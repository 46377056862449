import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationComponent } from './authentication/authentication.component';
import { ChangePasswordComponentComponent } from './authentication/change-password/change-password-component/change-password-component.component';
import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { EmailComponentComponent } from './authentication/email-verification/email-component/email-component.component';
import { EmailVerificationComponent } from './authentication/email-verification/email-verification.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { RecoveryComponentComponent } from './authentication/forgot-password/recovery-component/recovery-component.component';
import { LoginComponent } from './authentication/login/login.component';
import { OnboardingComponent } from './authentication/onboarding/onboarding.component';
import { SignUpComponent } from './authentication/sign-up/sign-up.component';
import { BillingDetailsComponent } from './pages/billing-details/billing-details.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CustomerStoriesDetailsComponent } from './pages/customer-stories-details/customer-stories-details.component';
import { CustomerStoriesComponent } from './pages/customer-stories/customer-stories.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { UsersComponent } from './pages/users/users.component';

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'customer-stories', component: CustomerStoriesComponent },
  {
    path: 'customer-stories-details',
    component: CustomerStoriesDetailsComponent,
  },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'my-profile', component: MyProfileComponent },
  { path: 'billing-details', component: BillingDetailsComponent },
  { path: 'users', component: UsersComponent },
  {
    path: 'onboarding',
    component: OnboardingComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'sign-up',
        component: SignUpComponent,
      },
      {
        path: 'forgot-password',
        component: RecoveryComponentComponent,
      },
      {
        path: 'email-verification',
        component: EmailComponentComponent,
      },
      {
        path: 'change-password',
        component: ChangePasswordComponentComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
