<div class="container">
  <h1 class="nav-link active text-center">Email Verification</h1>
  <p class="my-4 sub_text text-center">
    Enter the one-time-password sent to you to verifiy your email
  </p>
  <div class="d-flex align-items-center justify-coontent-center">
    <ng-otp-input
      class="w-100 text-center"
      (onInputChange)="onOtpChange($event)"
      [config]="{ length: 4 }"
    ></ng-otp-input>
  </div>
  <button class="btn w-100 btn_login mt-4 mb-2" (click)="onSubmit()">
    Verify Email
  </button>
  <button class="btn btn-transparent forgot_text mt-2" (click)="resendCode()">
    Resend OTP
  </button>
</div>
