<div class="wrapper">
  <div class="d-flex align-items-start bg">
    <div class="custom_container" [ngClass]="setWidthClasses()">
      <div class="menu menu-open" [ngClass]="setContainerClasses()">
        <div class="d-flex align-items-start justify-content-between mt-5 mtt-5">
          <div class="logo">
            <a routerLink="/">
              <img
                src="../../../../assets/dashboard/Group 13.svg"
                alt=""
                class="navbar-brand"
              />
            </a>
          </div>
          <div
            class="d-flex align-items-start search_region justify-content-between"
          >
            <div class="search_area d-flex align-items-center mr-3 mb-3">
              <div class="">
                <i class="fas fa-search ml-3 mr-2"></i
                ><input
                  type="search"
                  placeholder="Search for apps…"
                  class="search mr-3"
                  name=""
                  id=""
                />
              </div>
            </div>
            <div class="d-flex align-items-center ml-5">
              <div class="nav-icon">
                <div class="dropdown">
                  <button
                    type="button"
                    class="border-0"
                    id="dropdownMenuOffset"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-offset="-20,20"
                  >
                    <div class="d-flex align-items-center">
                      <div class="container">
                        <p class="welcome_text p-0 m-0">Hello, Toby</p>
                        <h4 class="name_text">My Account</h4>
                      </div>
                      <i class="fas fa-angle-down ml-3"></i>
                    </div>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuOffset"
                  >
                    <div class="d-flex align-items-center p-3 mr-4">
                      <img
                        src="../../../assets/dashboard/Ellipse 10.svg"
                        alt=""
                      />
                      <div class="ml-2">
                        <h2 class="backdrop_title mb-0">Sarah Shaibu</h2>
                        <p class="backdrop_email mb-0">
                          sarah.shaibu@gmail.com
                        </p>
                      </div>
                    </div>
                    <a class="nav-link mt-3" routerLink="/users">User</a>
                    <a class="dropdown-item nav-link" routerLink="/settings"
                      >My Account</a
                    >
                    <a class="nav-link" href="#">Help</a>
                    <div class="pl-3 pr-3 pt-4">
                      <p class="border-top pt-3">
                        <a class="logout"> Log Out </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="profile_pic">
                <img
                  src="../../../../assets/dashboard/Ellipse 1.svg"
                  alt=""
                  class="ml-3 profile_margin"
                />
              </div>
            </div>
            <button class="toggler" (click)="sidebarToggler()">
              <div class="div">
                <img
                  src="../../../../assets/dashboard/list.svg"
                  class="navicon"
                  alt=""
                  [style.display]="toggle ? 'none' : 'block'"
                />
              </div>
            </button>
          </div>
        </div>
        <h2 class="title mt-4 dashboard_margin">Dashboard</h2>
        <div class="row mt-5 text-center">
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/feedback.svg" alt="" />
            <p class="text mt-2 mb-5">feedback</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/talk.svg" alt="" />
            <p class="text mt-2 mb-5">talk</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/tickets.svg" alt="" />
            <p class="text mt-2 mb-5">tickets</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/knowledge.svg" alt="" />
            <p class="text mt-2 mb-5">knowledge</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/email.svg" alt="" />
            <p class="text mt-2 mb-5">email</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/book.svg" alt="" />
            <p class="text mt-2 mb-5">book</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/performance.svg" alt="" />
            <p class="text mt-2 mb-5">performance</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/report.svg" alt="" />
            <p class="text mt-2 mb-5">report</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/schedule.svg" alt="" />
            <p class="text mt-2 mb-5">schedule</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/accounts.svg" alt="" />
            <p class="text mt-2 mb-5">accounts</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/users.svg" alt="" />
            <p class="text mt-2 mb-5">users</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/talents.svg" alt="" />
            <p class="text mt-2 mb-5">talents</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/contacts.svg" alt="" />
            <p class="text mt-2 mb-5">contacts</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/staff.svg" alt="" />
            <p class="text mt-2 mb-5">staff</p>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
            <img src="../../../assets/homepage/settings.svg" alt="" />
            <p class="text mt-2 mb-5">settings</p>
          </div>
        </div>
      </div>
      <div class="menu-side">
        <div
          class="right_pane_bg right_pane scroll h-100"
          [ngClass]="setPaneClasses()"
        >
          <div class="container mt-5 mtt-5">
            <div class="d-flex align-items-center justify-content-between mb-5">
              <h5 class="right_header">Activity Board</h5>
              <button class="btn text-right pr-0" (click)="sidebarToggler()">
                <img src="../../../../assets/dashboard/x.svg" alt="" />
              </button>
            </div>
            <div class="">
              <div
                class="d-flex align-items-start justify-content-between mt-4"
              >
                <div class="d-flex ais">
                  <div class="d-flex align-items-start">
                    <img
                      src="../../../assets/dashboard/notif_pane/Dot.svg"
                      class="mt-2"
                      alt=""
                    />
                    <img
                      src="../../../assets/dashboard/Avatar.svg"
                      class="ml-2"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <div class="d-flex align-items-start">
                      <h5 class="notif_title mb-0">Gunther Ackner</h5>
                      <p class="notif_text ml-3 mb-0">4mins</p>
                    </div>
                    <p class="notif_text">
                      replied to your comment on Landin_Page.Sketch
                    </p>
                  </div>
                </div>
                <img
                  src="../../../assets/dashboard/notif_pane/message-icon.svg"
                  class="ml-3"
                  alt=""
                />
              </div>
              <div
                class="d-flex align-items-start justify-content-between mt-4"
              >
                <div class="d-flex ais">
                  <div class="d-flex align-items-start">
                    <img
                      src="../../../assets/dashboard/notif_pane/Dot.svg"
                      class="mt-2"
                      alt=""
                    />
                    <img
                      src="../../../assets/dashboard/Avatar.svg"
                      class="ml-2"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <div class="d-flex align-items-start">
                      <h5 class="notif_title mb-0">Marriet Miles</h5>
                      <p class="notif_text ml-3 mb-0">4mins</p>
                    </div>
                    <p class="notif_text">
                      invited you to a new channel named Analytics
                    </p>
                    <button class="btn green_btn">
                      <img
                        src="../../../assets/dashboard/notif_pane/Check.svg"
                        alt=""
                        class="mr-2 hide-me"
                      />Accept
                    </button>
                    <button class="btn blue_btn ml-2">
                      <img
                        src="../../../assets/dashboard/notif_pane/Close.svg"
                        alt=""
                        class="mr-2 hide-me"
                      />Ignore
                    </button>
                  </div>
                </div>
                <img
                  src="../../../assets/dashboard/notif_pane/calendar.svg"
                  class="ml-3"
                  alt=""
                />
              </div>
              <div
                class="d-flex align-items-start justify-content-between mt-4"
              >
                <div class="d-flex ais">
                  <div class="d-flex align-items-start">
                    <img
                      src="../../../assets/dashboard/notif_pane/Dot.svg"
                      class="mt-2"
                      alt=""
                    />
                    <img
                      src="../../../assets/dashboard/Avatar.svg"
                      class="ml-2"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <div class="d-flex align-items-start">
                      <h5 class="notif_title mb-0">Meysam Nassour</h5>
                      <p class="notif_text ml-3 mb-0">4mins</p>
                    </div>
                    <p class="notif_text">assigned to you a new task</p>
                  </div>
                </div>
                <img
                  src="../../../assets/dashboard/notif_pane/star.svg"
                  class="ml-3"
                  alt=""
                />
              </div>
              <div
                class="d-flex align-items-start justify-content-between mt-4"
              >
                <div class="d-flex ais">
                  <div class="d-flex align-items-start">
                    <img
                      src="../../../assets/dashboard/notif_pane/Dot.svg"
                      class="mt-2"
                      alt=""
                    />
                    <img
                      src="../../../assets/dashboard/Avatar.svg"
                      class="ml-2"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <div class="d-flex align-items-start">
                      <h5 class="notif_title mb-0">Ralph Guerrero</h5>
                      <p class="notif_text ml-3 mb-0">4mins</p>
                    </div>
                    <p class="notif_text">
                      deleted 20 files from Reports folder
                    </p>
                  </div>
                </div>
                <img
                  src="../../../assets/dashboard/notif_pane/data-pile.svg"
                  class="ml-3"
                  alt=""
                />
              </div>
              <div
                class="d-flex align-items-start justify-content-between mt-4"
              >
                <div class="d-flex ais">
                  <div class="d-flex align-items-start">
                    <img
                      src="../../../assets/dashboard/notif_pane/Dot.svg"
                      class="mt-2"
                      alt=""
                    />
                    <img
                      src="../../../assets/dashboard/Avatar.svg"
                      class="ml-2"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <div class="d-flex align-items-start">
                      <h5 class="notif_title mb-0">Margie Jutten</h5>
                      <p class="notif_text ml-3 mb-0">4mins</p>
                    </div>
                    <p class="notif_text">
                      invited you to a new channel named Reports
                    </p>
                    <button class="btn green_btn">
                      <img
                        src="../../../assets/dashboard/notif_pane/Check.svg"
                        alt=""
                        class="mr-2 hide-me"
                      />Accept
                    </button>
                    <button class="btn blue_btn ml-2">
                      <img
                        src="../../../assets/dashboard/notif_pane/Close.svg"
                        alt=""
                        class="mr-2 hide-me"
                      />Ignore
                    </button>
                  </div>
                </div>
                <img
                  src="../../../assets/dashboard/notif_pane/network.svg"
                  class="ml-3"
                  alt=""
                />
              </div>
              <div
                class="d-flex align-items-start justify-content-between mt-4"
              >
                <div class="d-flex ais">
                  <div class="d-flex align-items-start">
                    <img
                      src="../../../assets/dashboard/notif_pane/Dot.svg"
                      class="mt-2"
                      alt=""
                    />
                    <img
                      src="../../../assets/dashboard/Avatar.svg"
                      class="ml-2"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <div class="d-flex align-items-start">
                      <h5 class="notif_title mb-0">Billy Green</h5>
                      <p class="notif_text ml-3 mb-0">4mins</p>
                    </div>
                    <p class="notif_text">moved a task to pending</p>
                  </div>
                </div>
                <img
                  src="../../../assets/dashboard/notif_pane/network.svg"
                  class="ml-3"
                  alt=""
                />
              </div>
              <div
                class="d-flex align-items-start justify-content-between mt-4"
              >
                <div class="d-flex ais">
                  <div class="d-flex align-items-start">
                    <img
                      src="../../../assets/dashboard/notif_pane/Dot.svg"
                      class="mt-2"
                      alt=""
                    />
                    <img
                      src="../../../assets/dashboard/Avatar.svg"
                      class="ml-2"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <div class="d-flex align-items-start">
                      <h5 class="notif_title mb-0">Mina Fleming</h5>
                      <p class="notif_text ml-3 mb-0">4mins</p>
                    </div>
                    <p class="notif_text">shared a folder with you</p>
                  </div>
                </div>
                <img
                  src="../../../assets/dashboard/notif_pane/network 2.svg"
                  class="ml-3"
                  alt=""
                />
              </div>
              <div
                class="d-flex align-items-start justify-content-between mt-4"
              >
                <div class="d-flex ais">
                  <div class="d-flex align-items-start">
                    <img
                      src="../../../assets/dashboard/notif_pane/Dot.svg"
                      class="mt-2"
                      alt=""
                    />
                    <img
                      src="../../../assets/dashboard/Avatar.svg"
                      class="ml-2"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <div class="d-flex align-items-start">
                      <h5 class="notif_title mb-0">Tommy Dawson</h5>
                      <p class="notif_text ml-3 mb-0">4mins</p>
                    </div>
                    <p class="notif_text">
                      uploaded a new file to Reports folder
                    </p>
                  </div>
                </div>
                <img
                  src="../../../assets/dashboard/notif_pane/Icon.svg"
                  class="ml-3"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <button class="nav_btn text-left">
      <div class="icon_margin">
        <img
          src="../../../../assets/dashboard/list.svg"
          class="navicon"
          alt=""
        />
      </div>
      <div class="right_pane_bg right_pane scroll h-100">
        <div class="container mt-5">
          <div class="d-flex align-items-center justify-content-between mb-5">
            <h5 class="right_header">Activity Board</h5>
            <button class="btn text-right pr-0">
              <img src="../../../../assets/dashboard/x.svg" alt="" />
            </button>
          </div>
          <div class="">
            <div class="d-flex align-items-start justify-content-between mt-4">
              <div class="d-flex ais">
                <div class="d-flex align-items-start">
                  <img
                    src="../../../assets/dashboard/notif_pane/Dot.svg"
                    class="mt-2"
                    alt=""
                  />
                  <img
                    src="../../../assets/dashboard/Avatar.svg"
                    class="ml-2"
                    alt=""
                  />
                </div>
                <div class="ml-3">
                  <div class="d-flex align-items-start">
                    <h5 class="notif_title mb-0">Gunther Ackner</h5>
                    <p class="notif_text ml-3 mb-0">4mins</p>
                  </div>
                  <p class="notif_text">
                    replied to your comment on Landin_Page.Sketch
                  </p>
                </div>
              </div>
              <img
                src="../../../assets/dashboard/notif_pane/message-icon.svg"
                class="ml-3"
                alt=""
              />
            </div>
            <div class="d-flex align-items-start justify-content-between mt-4">
              <div class="d-flex ais">
                <div class="d-flex align-items-start">
                  <img
                    src="../../../assets/dashboard/notif_pane/Dot.svg"
                    class="mt-2"
                    alt=""
                  />
                  <img
                    src="../../../assets/dashboard/Avatar.svg"
                    class="ml-2"
                    alt=""
                  />
                </div>
                <div class="ml-3">
                  <div class="d-flex align-items-start">
                    <h5 class="notif_title mb-0">Marriet Miles</h5>
                    <p class="notif_text ml-3 mb-0">4mins</p>
                  </div>
                  <p class="notif_text">
                    invited you to a new channel named Analytics
                  </p>
                  <button class="btn green_btn">
                    <img
                      src="../../../assets/dashboard/notif_pane/Check.svg"
                      alt=""
                      class="mr-2 hide-me"
                    />Accept
                  </button>
                  <button class="btn blue_btn ml-2">
                    <img
                      src="../../../assets/dashboard/notif_pane/Close.svg"
                      alt=""
                      class="mr-2 hide-me"
                    />Ignore
                  </button>
                </div>
              </div>
              <img
                src="../../../assets/dashboard/notif_pane/calendar.svg"
                class="ml-3"
                alt=""
              />
            </div>
            <div class="d-flex align-items-start justify-content-between mt-4">
              <div class="d-flex ais">
                <div class="d-flex align-items-start">
                  <img
                    src="../../../assets/dashboard/notif_pane/Dot.svg"
                    class="mt-2"
                    alt=""
                  />
                  <img
                    src="../../../assets/dashboard/Avatar.svg"
                    class="ml-2"
                    alt=""
                  />
                </div>
                <div class="ml-3">
                  <div class="d-flex align-items-start">
                    <h5 class="notif_title mb-0">Meysam Nassour</h5>
                    <p class="notif_text ml-3 mb-0">4mins</p>
                  </div>
                  <p class="notif_text">assigned to you a new task</p>
                </div>
              </div>
              <img
                src="../../../assets/dashboard/notif_pane/star.svg"
                class="ml-3"
                alt=""
              />
            </div>
            <div class="d-flex align-items-start justify-content-between mt-4">
              <div class="d-flex ais">
                <div class="d-flex align-items-start">
                  <img
                    src="../../../assets/dashboard/notif_pane/Dot.svg"
                    class="mt-2"
                    alt=""
                  />
                  <img
                    src="../../../assets/dashboard/Avatar.svg"
                    class="ml-2"
                    alt=""
                  />
                </div>
                <div class="ml-3">
                  <div class="d-flex align-items-start">
                    <h5 class="notif_title mb-0">Ralph Guerrero</h5>
                    <p class="notif_text ml-3 mb-0">4mins</p>
                  </div>
                  <p class="notif_text">deleted 20 files from Reports folder</p>
                </div>
              </div>
              <img
                src="../../../assets/dashboard/notif_pane/data-pile.svg"
                class="ml-3"
                alt=""
              />
            </div>
            <div class="d-flex align-items-start justify-content-between mt-4">
              <div class="d-flex ais">
                <div class="d-flex align-items-start">
                  <img
                    src="../../../assets/dashboard/notif_pane/Dot.svg"
                    class="mt-2"
                    alt=""
                  />
                  <img
                    src="../../../assets/dashboard/Avatar.svg"
                    class="ml-2"
                    alt=""
                  />
                </div>
                <div class="ml-3">
                  <div class="d-flex align-items-start">
                    <h5 class="notif_title mb-0">Margie Jutten</h5>
                    <p class="notif_text ml-3 mb-0">4mins</p>
                  </div>
                  <p class="notif_text">
                    invited you to a new channel named Reports
                  </p>
                  <button class="btn green_btn">
                    <img
                      src="../../../assets/dashboard/notif_pane/Check.svg"
                      alt=""
                      class="mr-2 hide-me"
                    />Accept
                  </button>
                  <button class="btn blue_btn ml-2">
                    <img
                      src="../../../assets/dashboard/notif_pane/Close.svg"
                      alt=""
                      class="mr-2 hide-me"
                    />Ignore
                  </button>
                </div>
              </div>
              <img
                src="../../../assets/dashboard/notif_pane/network.svg"
                class="ml-3"
                alt=""
              />
            </div>
            <div class="d-flex align-items-start justify-content-between mt-4">
              <div class="d-flex ais">
                <div class="d-flex align-items-start">
                  <img
                    src="../../../assets/dashboard/notif_pane/Dot.svg"
                    class="mt-2"
                    alt=""
                  />
                  <img
                    src="../../../assets/dashboard/Avatar.svg"
                    class="ml-2"
                    alt=""
                  />
                </div>
                <div class="ml-3">
                  <div class="d-flex align-items-start">
                    <h5 class="notif_title mb-0">Billy Green</h5>
                    <p class="notif_text ml-3 mb-0">4mins</p>
                  </div>
                  <p class="notif_text">moved a task to pending</p>
                </div>
              </div>
              <img
                src="../../../assets/dashboard/notif_pane/network.svg"
                class="ml-3"
                alt=""
              />
            </div>
            <div class="d-flex align-items-start justify-content-between mt-4">
              <div class="d-flex ais">
                <div class="d-flex align-items-start">
                  <img
                    src="../../../assets/dashboard/notif_pane/Dot.svg"
                    class="mt-2"
                    alt=""
                  />
                  <img
                    src="../../../assets/dashboard/Avatar.svg"
                    class="ml-2"
                    alt=""
                  />
                </div>
                <div class="ml-3">
                  <div class="d-flex align-items-start">
                    <h5 class="notif_title mb-0">Mina Fleming</h5>
                    <p class="notif_text ml-3 mb-0">4mins</p>
                  </div>
                  <p class="notif_text">shared a folder with you</p>
                </div>
              </div>
              <img
                src="../../../assets/dashboard/notif_pane/network 2.svg"
                class="ml-3"
                alt=""
              />
            </div>
            <div class="d-flex align-items-start justify-content-between mt-4">
              <div class="d-flex ais">
                <div class="d-flex align-items-start">
                  <img
                    src="../../../assets/dashboard/notif_pane/Dot.svg"
                    class="mt-2"
                    alt=""
                  />
                  <img
                    src="../../../assets/dashboard/Avatar.svg"
                    class="ml-2"
                    alt=""
                  />
                </div>
                <div class="ml-3">
                  <div class="d-flex align-items-start">
                    <h5 class="notif_title mb-0">Tommy Dawson</h5>
                    <p class="notif_text ml-3 mb-0">4mins</p>
                  </div>
                  <p class="notif_text">
                    uploaded a new file to Reports folder
                  </p>
                </div>
              </div>
              <img
                src="../../../assets/dashboard/notif_pane/Icon.svg"
                class="ml-3"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </button> -->
  </div>
</div>
