import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { HeaderComponent } from './pages/homepage/header/header.component';
import { TabComponent } from './pages/homepage/tab/tab.component';
import { SupportComponent } from './pages/homepage/support/support.component';
import { GridComponent } from './pages/homepage/grid/grid.component';
import { ContactComponent } from './pages/contact/contact.component';
import { RevenueComponent } from './pages/homepage/revenue/revenue.component';
import { ExperienceComponent } from './pages/homepage/experience/experience.component';
import { ContactHeaderComponent } from './pages/contact/contact-header/contact-header.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { OpenHoursComponent } from './pages/contact/open-hours/open-hours.component';
import { PricingHeaderComponent } from './pages/pricing/pricing-header/pricing-header.component';
import { PricingComponentComponent } from './pages/pricing/pricing-component/pricing-component.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FaqHeaderComponent } from './pages/faq/faq-header/faq-header.component';
import { CustomerStoriesComponent } from './pages/customer-stories/customer-stories.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { CustomerStoriesDetailsComponent } from './pages/customer-stories-details/customer-stories-details.component';
import { StoriesHeaderComponent } from './pages/customer-stories/stories-header/stories-header.component';
import { StoriesGridComponent } from './pages/customer-stories/stories-grid/stories-grid.component';
import { TermsHeaderComponent } from './pages/terms-and-conditions/terms-header/terms-header.component';
import { CDetailsHeaderComponent } from './pages/customer-stories-details/c-details-header/c-details-header.component';
import { ApplicationComponent } from './pages/customer-stories-details/application/application.component';
import { HowItWorksComponent } from './pages/customer-stories-details/how-it-works/how-it-works.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { LoginComponent } from './authentication/login/login.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { RecoveryComponentComponent } from './authentication/forgot-password/recovery-component/recovery-component.component';
import { EmailVerificationComponent } from './authentication/email-verification/email-verification.component';
import { EmailComponentComponent } from './authentication/email-verification/email-component/email-component.component';
import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { ChangePasswordComponentComponent } from './authentication/change-password/change-password-component/change-password-component.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardNavComponent } from './pages/dashboard/dashboard-nav/dashboard-nav.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { BillingDetailsComponent } from './pages/billing-details/billing-details.component';
import { BillingDetailsTabComponent } from './pages/billing-details/billing-details-tab/billing-details-tab.component';
import { UsersComponent } from './pages/users/users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorsService } from './shared/interceptors/interceptors.service';
import { NgOtpInputModule } from 'ng-otp-input';
import { SignUpComponent } from './authentication/sign-up/sign-up.component';
import { OnboardingComponent } from './authentication/onboarding/onboarding.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomepageComponent,
    HeaderComponent,
    TabComponent,
    SupportComponent,
    GridComponent,
    ContactComponent,
    RevenueComponent,
    ExperienceComponent,
    ContactHeaderComponent,
    PricingComponent,
    OpenHoursComponent,
    PricingHeaderComponent,
    PricingComponentComponent,
    FaqComponent,
    FaqHeaderComponent,
    CustomerStoriesComponent,
    TermsAndConditionsComponent,
    PrivacyComponent,
    CustomerStoriesDetailsComponent,
    StoriesHeaderComponent,
    StoriesGridComponent,
    TermsHeaderComponent,
    CDetailsHeaderComponent,
    ApplicationComponent,
    HowItWorksComponent,
    AuthenticationComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RecoveryComponentComponent,
    EmailVerificationComponent,
    EmailComponentComponent,
    ChangePasswordComponent,
    ChangePasswordComponentComponent,
    DashboardComponent,
    DashboardNavComponent,
    SettingsComponent,
    MyProfileComponent,
    BillingDetailsComponent,
    BillingDetailsTabComponent,
    UsersComponent,
    SignUpComponent,
    OnboardingComponent,
  ],
  imports: [
    CommonModule,
    NgOtpInputModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorsService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
