import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-stories-details',
  templateUrl: './customer-stories-details.component.html',
  styleUrls: ['./customer-stories-details.component.css']
})
export class CustomerStoriesDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
