<div class="container">
  <h1 class="nav-link active text-center">Set New Password</h1>
  <p class="mt-3 sub_text text-center">
    Now let’s pick a password you can always remember
  </p>
  <div [formGroup]="changePassword">
    <div class="d-flex align-items-center justify-coontent-center">
      <ng-otp-input
        class="w-100 text-center"
        (onInputChange)="onOtpChange($event)"
        [config]="{ length: 4 }"
      ></ng-otp-input>
    </div>
    <div
      class="input_field d-flex align-items-center mt-3 mb-2 justify-content-between pl-3 pr-3"
    >
      <input
        [type]="show ? 'text' : 'password'"
        class="input_area"
        placeholder="New Password"
        formControlName="password"
        name=""
        id=""
      />
      <button
        class="btn bg-transparent p-0 mr-2"
        (click)="password()"
        *ngIf="show"
      >
        <i class="fas fa-eye"></i>
      </button>
      <button
        class="btn bg-transparent p-0 mr-2"
        (click)="password()"
        *ngIf="!show"
      >
        <i class="fas fa-eye-slash"></i>
      </button>
    </div>
    <button class="btn w-100 btn_login mt-3" (click)="resetPassword()">
      Reset Password
    </button>
  </div>
</div>
