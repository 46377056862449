<div class="container-fluid border-bottom mb-5">
  <div class="container mt-4 mb-4">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between">
          <a href="#" class="hoolio_suite">hoolio suite</a>
          <div class="d-flex align-items-center">
            <a href="#" class="tab_text sm_hide">Overview</a>
            <a routerLink="/pricing" class="tab_text ml-3 sm_hide">Pricing</a>
            <button routerLink="/login" class="btn custom_btn_dark ml-3">Start free trial</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
