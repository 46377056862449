<div class="wrapper">
  <div class="my-profile pb-5">
    <app-dashboard-nav></app-dashboard-nav>
    <div class="container">
      <div class="header mt-5">
        <a routerLink="/settings" class="dashboard">
          <img
            src="../../../assets/stories-details/arrow-right.svg"
            class="mr-2"
            alt=""
          />
        </a>
        <span class="pt-3"> My Account: </span>
        <a routerLink="/" class="my_profile_link">My Profile</a>
        <h1 class="mt-4 title">My Profile</h1>
      </div>
      <div class="row">
        <div class="col-md-4 mt-5">
          <h2 class="sub_header">Profile Picture</h2>
          <img
            src="../../../assets/my-profile/Image.svg"
            alt=""
            class="card-img"
          />
          <div class="mt-4 d-flex align-items-center">
            <input type="file" class="custom_input" />
            <button class="delete_btn ml-3">
              <i class="far fa-trash-alt mr-2"></i>
              <span class="delete_text">Delete</span>
            </button>
          </div>
        </div>
        <span class="col-md-1"></span>
        <div class="col-md-7 custom_margin_top d-flex align-items-center">
          <div class="">
            <h2 class="sub_header">My Information</h2>
            <form>
              <div class="row">
                <div class="col-md-6 mt-4">
                  <label for="">Full Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Sarah Shaibu"
                  />
                </div>
                <div class="col-md-6 mt-4">
                  <label for="">Username</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Sarahshaibu"
                  />
                </div>
                <div class="col-md-6 mt-4">
                  <label for="">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="sarahshaibu@mail.com"
                  />
                </div>
                <div class="col-md-6 mt-4">
                  <label for="">Role</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Head of Marketing"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mt-4">
                  <label for="">Location</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="San Francisco, CA"
                  />
                </div>
                <div class="col-md-6 mt-4">
                  <label for="">Timezone</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="GMT -8 hours"
                  />
                </div>
                <div class="col-md-6 mt-4">
                  <label for="">Phone Number</label>
                  <input
                    type="tel"
                    class="form-control"
                    placeholder="+1 2345 67890"
                  />
                </div>
              </div>
            </form>
            <div class="d-flex justify-content-end mt-5">
              <button class="btn save_btn">Save Changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
