<div class="container">
  <div class="d-flex align-items-center justify-content-end pt-3 mb-2">
    <a routerLink="/faq" class="nav-link">FAQ</a>
    <a routerLink="/contact" class="nav-link pr-0">Contact Us</a>
  </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container">
    <a class="navbar-brand" routerLink="/"
      ><img src="../../../assets/homepage/Logo.svg" alt=""
    /></a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarColor03"
      aria-controls="navbarColor03"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarColor03">
      <span class="mx-auto"></span>
      <ul class="navbar-nav">
        <li class="nav-item active ml-4 product">
          <a class="nav-link" href="#"
            >Products
            <span class="sr-only">(current)</span>
          </a>
          <div class="custom_dropdown card-img-overlay">
            <div class="row">
              <div class="col-sm-12 col-md-11 col-lg-9 col-xl-8 mx-auto">
                <div class="container bg-white p-4 scroll">
                  <div
                    class="row backdrop mx-auto d-flex justify-content-center"
                  >
                    <div class="col-md-4 d-flex align-items-center">
                      <div class="">
                        <h1 class="title">PRODUCTS</h1>
                        <a href="#">
                          <div class="mt-5">
                            <h2 class="sub_header">Support Apps</h2>
                            <p class="text">The full support experience</p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">feedback</h2>
                            <p class="text">Integrated customer feedback</p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">talk</h2>
                            <p class="text">Integrated voice call platform</p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">tickets</h2>
                            <p class="text">Manage support tickets</p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">knowledge</h2>
                            <p class="text">
                              Create a dedicated knowledge base
                            </p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">performance</h2>
                            <p class="text">
                              Monitor your business performance
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="col-md-4 d-flex align-items-center">
                      <div class="">
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">Growth Apps</h2>
                            <p class="text">The full growth experience</p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">reports</h2>
                            <p class="text">Analytics and reporting</p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">email</h2>
                            <p class="text">Email marketing software</p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">schedule</h2>
                            <p class="text">Social media scheduling</p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">book</h2>
                            <p class="text">Book or plan meetings</p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">accounts</h2>
                            <p class="text">Simple financing solutions</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="col-md-4 d-flex align-items-center">
                      <div class="mt-4">
                        <a href="#">
                          <div class="mt-5">
                            <h2 class="sub_header">Management Apps</h2>
                            <p class="text">Management tools</p>
                          </div>
                          <a href="#"> </a>
                          <div class="mt-4">
                            <h2 class="sub_header">users</h2>
                            <p class="text">Manage your users</p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">talent</h2>
                            <p class="text">Recruit special talent</p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">contacts</h2>
                            <p class="text">
                              Store and group important contacts
                            </p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">staff</h2>
                            <p class="text">
                              Store and group important contacts
                            </p>
                          </div>
                        </a>
                        <a href="#">
                          <div class="mt-4">
                            <h2 class="sub_header">settings</h2>
                            <p class="text">
                              Store and group important contacts
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item ml-4">
          <a class="nav-link" routerLink="/pricing">Pricing</a>
        </li>
        <li class="nav-item ml-4">
          <a class="nav-link" routerLink="/customer-stories"
            >Customer Stories</a
          >
        </li>
        <li class="nav-item ml-4">
          <button routerLink="/login" class="btn nav_btn btn_font">Get Started</button>
        </li>
      </ul>
    </div>
  </div>
</nav>
