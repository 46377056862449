<div class="privacy">
  <div class="header">
    <app-terms-header></app-terms-header>
    <div class="container">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <h1 class="title mt-5 text-center">Privacy Policy</h1>
          <p class="text mt-5">
            <span class="sub_header"
              >1. Introduction: Our App and the Rubynav Service</span
            >
            <br />

            We are pleased to offer you access to our smart phone application
            (the “App”) and/or our website, rubynav.com, through which we offer
            a proprietary service (the “Rubynav Service”) designed to connect
            customer users of the App (“Customers”) interested in placing orders
            for food, groceries, beverages and other products and services from
            participating restaurants and other merchants (“Merchants”) with
            independent couriers (we call them “Rubers”) interested in
            delivering such orders. The term “you” refers to a Customer end user
            of the Rubynav Service Delivery.
            <br />
            <br />

            <span class="sub_header">2. Our Agreement:</span> This is a binding
            contract between you and us. You agree to our Privacy Policy. Please
            read these Terms of Service carefully. These Terms of Service and
            other documents we refer to in these Terms of Service, like our
            Privacy Policy (together, the “Agreement“), govern your access to
            the Favor Service. By clicking the “I AGREE” button, installing the
            App, or using the Rubynav Delivery Service, you agree, effective as
            of such date (the “Effective Date”) to be bound by this Agreement.
            You are only authorized to use the App and the Rubynav Delivery
            Service if you agree to abide by all applicable laws and this
            Agreement. Please read this Agreement carefully and save it. If you
            do not agree with it, you should not install the App and should
            discontinue use of the App and the Rubynav Delivery Service
            immediately. <br />
            <br />

            <span class="sub_header">3. About Us:</span> We are a technology
            company. We are not a courier or logistics provider. RubynavHeart
            Foundation Inc., a Texas corporation d/b/a Rubynav Delivery Service
            (“Rubynav,” (“RDS,” “us,” or “we”) is a technology provider. You
            acknowledge and agree that we are not a courier, logistics provider,
            common carrier or foodservice operator. We do not supply any food,
            groceries, beverages or other products that are delivered to you.
            Rubers are not our employees, representatives or agents. <br />
            <br />

            <span class="sub_header"
              >4. Provision of Rubynav Delivery Service and License Grant:</span
            >
            We grant you rights to use the App and the Rubynav Delivery Service.
            Subject to the terms and conditions of this Agreement, during the
            Term, Rubynav: (a) will provide you with access to the Rubynav
            Service, and (b) hereby grants you a limited, non-exclusive and
            nontransferable license to download, install and use the App on a
            smart phone, tablet or other mobile device that you own or control.
            <br />
            <br />

            <span class="sub_header">5. Representations About You:</span> You
            make certain representations about yourself. You represent and
            warrant that (a) you have read and understand this Agreement, (b)
            you are 18 years of age or older, (c) the information that you
            provide to us about you or your account in connection with the
            Rubynav Service will be current, true, accurate, supportable and
            complete, (d) you are not located in a country that is subject to a
            U.S. Government embargo, or that has been designated by the U.S.
            Government as a “terrorist supporting” country, and (e) you are not
            listed on any U.S. Government list of prohibited or restricted
            parties. Additionally, if you are entering into this Agreement on
            behalf of your organization, you represent that you are the
            authorized agent of the organization and have the legal authority to
            enter into this Agreement on its behalf. <br />
            <br />

            <span class="sub_header">6. Users:</span> Certain terms apply to
            Customers and to Rubers. There are two types of users of the Rubynav
            Delivery Service: (a) Customers who request Merchant products to be
            delivered, and (b) Rubers who provide delivery services. This
            Agreement applies to your use of the Rubynav Service as a Customer.
            As a Customer, you agree that you will: (i) as a condition to
            placing an order via the Rubynav Service, link your Rubynav account
            with a valid credit card, debit card and/or other authorized payment
            method (“Payment Method”), (ii) pay for the orders that you place
            via the Rubynav Service, and (iii) comply with all applicable laws
            at all times. You agree to keep your Payment Method information
            current at all times while using the Rubynav Service (such as your
            card number and expiration date). Additionally, you may add more
            than one Payment Method per account. If you add more than one
            Payment Method, you may have the option to designate one as a
            primary Payment Method. Customer acknowledges that each Ruber
            retains total and complete discretion as to the Ruber’s provision of
            services. You further acknowledge and agree that Rubynav has no
            responsibility and makes no guarantees regarding: (1) Rubers or
            their vehicles, or (2) Merchants or the quality of any products
            delivered by Rubers. Rubynav retains a portion of the payments made
            via the Favor Service, but solely as a service fee for facilitating
            the transactions and payments envisioned by this Agreement. You
            accept all risks related to interacting with and transacting with
            Rubers and Merchants and all risk related to the Merchant products
            that Rubers deliver to you. <br />
            <br />

            <span class="sub_header"
              >7. Restrictions On Use of the Rubynav Service:</span
            >
            The Rubynav Service may only be used for certain activities. You may
            not: (a) rent, lease, lend, sell, redistribute, reproduce or
            sublicense access to the Rubynav Service, (b) copy, decompile,
            reverse-engineer, disassemble, attempt to derive the source code of,
            modify, or create derivative works of the Rubynav Service, or any
            part of the Rubynav Service, or (c) use the account, username, or
            password of another user at any time, allow any other person to use
            your account or provide your passwords to any other person or
            entity. If you are a competitor of Rubynav, you are not authorized
            to use the Rubynav Service. You may not exploit the Rubynav Service
            in any unauthorized or unlawful way. You will not assert, nor will
            you authorize, assist, or encourage any third party to assert, any
            intellectual property infringement claim regarding the Rubynav
            Service. If the above restrictions are prohibited by applicable law
            or by one of our license(s), then the activities are permitted only
            to the extent necessary to comply with the law or licenses.
          </p>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
