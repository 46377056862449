<div class="wrapper">
  <div class="row">
    <div class="col-12 grid_bg">
      <div class="container">
        <div class="row mt-5 pt-5">
          <div class="col-md-6 col-lg-4 col-xl-4">
            <div class="sm-container">
              <h2 class="title">The Support Suite</h2>
              <p class="text">The solution for you</p>
              <div class="mt-5 pt-4 d-flex align-items-start">
                <img src="../../../../assets/homepage/feedback.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">feedback</h3>
                  <p class="sub_text">Integrated customer feedback</p>
                </div>
              </div>
              <div class="mt-5 d-flex align-items-start">
                <img src="../../../../assets/homepage/performance.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">performance</h3>
                  <p class="sub_text">Monitor your business performance</p>
                </div>
              </div>
              <div class="mt-5 d-flex align-items-start">
                <img src="../../../../assets/homepage/talk.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">talk</h3>
                  <p class="sub_text">Integrated voice call platform</p>
                </div>
              </div>
              <div class="mt-5 d-flex align-items-start">
                <img src="../../../../assets/homepage/tickets.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">ticket</h3>
                  <p class="sub_text">Manage support tickets</p>
                </div>
              </div>
              <div class="mt-5 d-flex align-items-start">
                <img src="../../../../assets/homepage/knowledge.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">knowledge</h3>
                  <p class="sub_text">Create a dedicated knowledge base</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-4">
            <div class="sm-container">
              <h2 class="title">The Growth Suite</h2>
              <p class="text">The solution for you</p>
              <div class="mt-5 pt-4 d-flex align-items-start">
                <img src="../../../../assets/homepage/report.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">report</h3>
                  <p class="sub_text">Analytics and reporting</p>
                </div>
              </div>
              <div class="mt-5 d-flex align-items-start">
                <img src="../../../../assets/homepage/email.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">email</h3>
                  <p class="sub_text">Email marketing software</p>
                </div>
              </div>
              <div class="mt-5 d-flex align-items-start">
                <img src="../../../../assets/homepage/schedule.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">schedule</h3>
                  <p class="sub_text">Social media scheduling</p>
                </div>
              </div>
              <div class="mt-5 d-flex align-items-start">
                <img src="../../../../assets/homepage/book.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">book</h3>
                  <p class="sub_text">Book or plan meetings</p>
                </div>
              </div>
              <div class="mt-5 d-flex align-items-start">
                <img src="../../../../assets/homepage/accounts.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">accounts</h3>
                  <p class="sub_text">Simple financing solutions</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-4">
            <div class="sm-container">
              <h2 class="title">The Management Suite</h2>
              <p class="text">The solution for you</p>
              <div class="mt-5 pt-4 d-flex align-items-start">
                <img src="../../../../assets/homepage/users.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">users</h3>
                  <p class="sub_text">Manage your users</p>
                </div>
              </div>
              <div class="mt-5 d-flex align-items-start">
                <img src="../../../../assets/homepage/talents.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">talent</h3>
                  <p class="sub_text">Recruit special talent</p>
                </div>
              </div>
              <div class="mt-5 d-flex align-items-start">
                <img src="../../../../assets/homepage/contacts.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">contacts</h3>
                  <p class="sub_text">Store and group important contacts</p>
                </div>
              </div>
              <div class="mt-5 d-flex align-items-start">
                <img src="../../../../assets/homepage/staff.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">staff</h3>
                  <p class="sub_text">Manage all your staff across</p>
                </div>
              </div>
              <div class="mt-5 d-flex align-items-start">
                <img src="../../../../assets/homepage/settings.svg" alt="" />
                <div class="ml-3">
                  <h3 class="sub_title">settings</h3>
                  <p class="sub_text">Administrative settings</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
