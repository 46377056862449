import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterState } from '@angular/router';
import * as Notiflix from 'notiflix';
import { HoolioAuthService } from 'src/app/shared/services/auth/hoolio-auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css'],
})
export class OnboardingComponent implements OnInit {
  registerForm: FormGroup | any;
  loginForm: FormGroup | any;
  application: string = '';
  type: string = '';
  show: boolean = false;
  path: any;
  login: boolean = false;
  signUp: boolean = false;

  constructor(
    private _auth: HoolioAuthService,
    private fb: FormBuilder,
    private _router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.application = this.activatedRoute.snapshot.queryParams.application;
    this.type = this.activatedRoute.snapshot.queryParams.type;
    const state: RouterState = _router.routerState;
    let url = state.snapshot.url;
    this.path = url.includes('login');
    this.login = url.includes('login');
    this.signUp = url.includes('sign-up');
  }

  password() {
    this.show = !this.show;
  }
  ngOnInit(): void {}
  navigator(route: any, type?: any) {
    if (type) {
      window.location.href = `/onboarding/${route}${this.application}&type=${type}`;
    } else {
      window.location.href = `/onboarding/${route}${this.application}`;
    }
  }
}
