import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billing-details-tab',
  templateUrl: './billing-details-tab.component.html',
  styleUrls: ['./billing-details-tab.component.css'],
})
export class BillingDetailsTabComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
