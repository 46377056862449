<div class="wrapper">
  <div class="billing_detail pb-5">
    <app-dashboard-nav></app-dashboard-nav>
    <div class="container">
      <div class="header mt-5">
        <a routerLink="/settings" class="dashboard">
          <img
            src="../../../assets/stories-details/arrow-right.svg"
            class="mr-2"
            alt=""
          />
          My Account:
        </a>
        <span>Billing Details</span>
        <h1 class="mt-4 title">Billing Details</h1>
      </div>
      <app-billing-details-tab></app-billing-details-tab>
    </div>
  </div>
</div>
