<div>
  <h1 class="nav-link active text-center" *ngIf="!type">Sign Up</h1>
  <form [formGroup]="registerForm">
    <div class="input_field d-flex align-items-center mt-3 pl-3 pr-3">
      <input
        type="text"
        class="input_area w-100"
        placeholder="First Name"
        formControlName="first_name"
      />
    </div>
    <p
      class="text-danger forgot_text ml-3"
      *ngIf="hasErrorSignUp('first_name', 'required')"
    >
      First name is required
    </p>
    <div class="input_field d-flex align-items-center mt-3 pl-3 pr-3">
      <input
        type="text"
        class="input_area w-100"
        placeholder="Last Name"
        formControlName="last_name"
      />
    </div>
    <p
      class="text-danger forgot_text ml-3 pl-1"
      *ngIf="hasErrorSignUp('last_name', 'required')"
    >
      Last name is required
    </p>
    <div class="input_field d-flex align-items-center mt-4 pl-3 pr-3">
      <input
        type="email"
        class="input_area w-100"
        placeholder="Email address"
        formControlName="email"
      />
    </div>
    <p
      class="text-danger forgot_text ml-3 pl-1"
      *ngIf="hasErrorSignUp('email', 'email')"
    >
      Enter a valid email address
    </p>
    <p
      class="text-danger forgot_text ml-3 pl-1"
      *ngIf="hasErrorSignUp('email', 'required')"
    >
      Email address is required
    </p>
    <div
      class="input_field d-flex align-items-center mt-3 justify-content-between pl-3 pr-3"
    >
      <input
        [type]="show ? 'text' : 'password'"
        class="input_area"
        placeholder="Password"
        formControlName="password"
        name=""
        id=""
      />
      <button
        class="btn bg-transparent p-0 mr-2"
        (click)="password()"
        *ngIf="show"
      >
        <i class="fas fa-eye"></i>
      </button>
      <button
        class="btn bg-transparent p-0 mr-2"
        (click)="password()"
        *ngIf="!show"
      >
        <i class="fas fa-eye-slash"></i>
      </button>
    </div>
    <button
      class="btn w-100 btn_login mt-4"
      [disabled]="registerForm.invalid"
      (click)="registerUser()"
    >
      Sign Up
    </button>
  </form>
</div>
