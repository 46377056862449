<div class="wrapper">
  <div class="row mt-5">
    <div class="col-md-10 revenue_bg mx-auto mt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6 d-flex align-items-center">
            <div class="pt-5 pb-5">
              <h4 class="sub_title">SEIZE GREATER LEADS</h4>
              <h1 class="title">Turn customers into revenue</h1>
              <p class="text">
                The Support Suite allows your business to have natural
                conversations with your customers, without letting them feel
                what’s going on behind the scenes. It’s sophisticated enough to
                meet complex needs, and simple enough to let you get up and
                running immediately.
              </p>
              <div class="mt-5">
                <a class="link_text">Learn more</a
                ><a href="#" class="link_text ml-3">Watch Video</a>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="container pt-5 pb-5 d-flex align-items-center">
              <img
                src="../../../../assets/homepage/Rectangle 130.svg"
                alt=""
                class="card-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
