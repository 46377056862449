import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as Notiflix from 'notiflix';
import { HoolioAuthService } from 'src/app/shared/services/auth/hoolio-auth.service';

@Component({
  selector: 'app-change-password-component',
  templateUrl: './change-password-component.component.html',
  styleUrls: ['./change-password-component.component.css'],
})
export class ChangePasswordComponentComponent implements OnInit {
  code: any;
  show: boolean = false;
  changePassword: FormGroup | any;

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _auth: HoolioAuthService
  ) {}

  ngOnInit(): void {
    this.initChangePasswordForm();
  }

  initChangePasswordForm() {
    this.changePassword = this._fb.group({
      password: '',
      password_confirmation: '',
      email: localStorage.getItem('reset_email'),
      application: localStorage.getItem('app_redirect'),
      token: '',
    });
  }

  password() {
    this.show = !this.show;
  }

  onOtpChange(event: any) {
    this.code = event;
  }

  resetPassword() {
    Notiflix.Loading.circle();
    this.changePassword.value.password_confirmation =
      this.changePassword.value.password;
    this.changePassword.value.token = this.code;
    this._auth.resetPassword(this.changePassword.value).subscribe(
      (response: any) => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success(response.message, {
          useIcon: false,
          timeout: 5000,
        });
        window.location.href = `/onboarding/login?application=${localStorage.getItem(
          'app_redirect'
        )}`;
      },
      (error) => {
        Notiflix.Loading.remove();
      }
    );
  }
}
