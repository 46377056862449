import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-hours',
  templateUrl: './open-hours.component.html',
  styleUrls: ['./open-hours.component.css']
})
export class OpenHoursComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
