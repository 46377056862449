<div class="container">
  <div class="row mt-5">
    <div class="col-md-6 d-flex align-items-center">
      <div class="">
        <a routerLink="/customer-stories" class="sub_header"
          ><img
            src="../../../../assets/stories-details/arrow-right.svg"
            class="mr-3"
            alt=""
          />Back to Customer Stories</a
        >
        <h2 class="title mt-4">Hotelo + Hoolio a great solution</h2>
        <p class="text mt-5">
          Hotelo is a company that deals in hotelier premium concieerge
          services, utilizing our suite they make sure to stay in touch with
          vendors in an efficient manner
        </p>
        <div class="mt-5">
          <button routerLink="/login" class="btn btn_dark">Get Started</button>
          <button routerLink="/pricing" class="btn btn_light ml-3">View Pricing</button>
        </div>
      </div>
    </div>
    <div class="col-md-6 d-flex align-items-center custom_margin_top">
      <img
        src="../../../../assets/customer-stories/Rectangle 141.svg"
        alt=""
        class="card-img"
      />
    </div>
  </div>
</div>
