<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-md-10 mx-auto">
      <div
        class="d-flex rounded align-items-center justify-content-between bg-light mt-5"
        data-toggle="collapse"
        href="#about"
        role="button"
        aria-expanded="false"
        aria-controls="multiCollapseExample1"
      >
        <div class="container">
          <div class="d-flex align-items-center justify-content-between">
            <h2 class="backdrop_title">All About Hoolio Suite</h2>
            <img src="../../../../assets/pricing/Vector.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="collapse multi-collapse" id="about">
              <div class="mt-5">
                <h2 class="title mt-4">How do I change my phone number?</h2>
                <p class="text mt-4">
                  We would be happy to help you change the phone number on your
                  account. Please email our Support team at
                  accountupdates@rubynav.com with a summary of your request. To
                  expedite the process, please send the email from the address
                  that’s linked to your account.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">
                  What can I have delivered with Rubynav?
                </h2>
                <p class="text mt-4">
                  Almost anything! We deliver food from your favorite
                  restaurants, groceries from your favorite grocery store, any
                  errand of yours that includes prescription pickup, drycleaner,
                  or mail pickup & drop off, and of course also have the right
                  Gig Pros at your doorstep.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">What is Rubynav?</h2>
                <p class="text mt-4">
                  Rubynav is the easiest way to get anything you want in your
                  city delivered to your door in under an hour be it Food,
                  Grocery, quick errands, or gig pros
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">Are you expanding?</h2>
                <p class="text mt-4">
                  We will absolutely be expanding into new cities and areas. We
                  always put customer service first before growing too quickly.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">How can I purchase a Ruby gift card?</h2>
                <p class="text mt-4">
                  Rubynav gift cards are a great way to give the gift of
                  Anything Delivered! To purchase a Ruby gift card, go to
                  Rubynav.com/gift-cards. Choose a design, desired amount ($25
                  minimum, $500 maximum), and your gift card will be sent via
                  email.
                </p>
                <p class="text mt-4">
                  Gift cards can be applied to the entire cost of the order
                  including fees, tip, and the cost of items. You can use
                  Rubynav in over 100 cities across Texas.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="collapse multi-collapse" id="about">
              <div class="mt-5">
                <h2 class="title mt-4">Can I have alcohol delivered?</h2>
                <p class="text mt-4">
                  Rubynav delivers beer and wine from all stores. For the safety
                  of both Rubers and customers, all deliveries are now 100%
                  contactless. You will scan your ID and submit a selfie in the
                  app which will be verified by your Rubers prior to delivery.
                  If you are
                </p>
                <p class="text">
                  In most cities, you can order beer and wine from 7am to 11pm
                  Monday through Friday, 7am to 12am on Saturday, and 12pm to
                  11pm on Sunday. However, operation hours vary by market.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">How does Rubynav work?</h2>
                <p class="text mt-4">
                  Go to Rubynav.com or download the Ruby app to browse popular
                  nearby restaurants and Grocery stores, Errands, Gig-Pros or
                  tap the ‘search’ icon to order from any store within your
                  delivery zone. You can add items from our menus or type in
                  custom orders. After you order your Rubynav, it is assigned to
                  one of our Rubers, who will complete your delivery.
                </p>
                <p class="text">
                  Rubers act as your personal assistant that you can text
                  throughout entire delivery, so if you decide you need to make
                  any changes, text your Ruber to let them know.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">How do I pay for a Ruby?</h2>
                <p class="text mt-4">
                  Ruby is completely cashless. All payment is made through our
                  app and we accept all major credit and debit cards. When you
                  order your first Rubynav, you’ll enter your payment
                  information, which we store securely. You only need to enter
                  your payment info once. After you place a Rubynav, we will
                  perform a pre-authorization on your default payment method.
                  Additionally, you will have a short time after it’s been
                  completed to proactively pay. During this time you can add a
                  promo code, change the suggested Ruber tip, and choose which
                  card you’d like to use. After this short time, we will
                  automatically charge your default payment method and the
                  suggested tip.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-10 mx-auto">
      <div
        class="d-flex align-items-center justify-content-between bg-light mt-5 rounded"
        data-toggle="collapse"
        href="#payment"
        role="button"
        aria-expanded="false"
        aria-controls="multiCollapseExample1"
      >
        <div class="container">
          <div class="d-flex align-items-center justify-content-between">
            <h2 class="backdrop_title">Payment</h2>
            <img src="../../../../assets/pricing/Vector.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="collapse multi-collapse" id="payment">
              <div class="mt-5">
                <h2 class="title mt-4">How do I change my phone number?</h2>
                <p class="text mt-4">
                  We would be happy to help you change the phone number on your
                  account. Please email our Support team at
                  accountupdates@rubynav.com with a summary of your request. To
                  expedite the process, please send the email from the address
                  that’s linked to your account.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">
                  What can I have delivered with Rubynav?
                </h2>
                <p class="text mt-4">
                  Almost anything! We deliver food from your favorite
                  restaurants, groceries from your favorite grocery store, any
                  errand of yours that includes prescription pickup, drycleaner,
                  or mail pickup & drop off, and of course also have the right
                  Gig Pros at your doorstep.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">What is Rubynav?</h2>
                <p class="text mt-4">
                  Rubynav is the easiest way to get anything you want in your
                  city delivered to your door in under an hour be it Food,
                  Grocery, quick errands, or gig pros
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">Are you expanding?</h2>
                <p class="text mt-4">
                  We will absolutely be expanding into new cities and areas. We
                  always put customer service first before growing too quickly.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">How can I purchase a Ruby gift card?</h2>
                <p class="text mt-4">
                  Rubynav gift cards are a great way to give the gift of
                  Anything Delivered! To purchase a Ruby gift card, go to
                  Rubynav.com/gift-cards. Choose a design, desired amount ($25
                  minimum, $500 maximum), and your gift card will be sent via
                  email.
                </p>
                <p class="text mt-4">
                  Gift cards can be applied to the entire cost of the order
                  including fees, tip, and the cost of items. You can use
                  Rubynav in over 100 cities across Texas.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="collapse multi-collapse" id="payment">
              <div class="mt-5">
                <h2 class="title mt-4">Can I have alcohol delivered?</h2>
                <p class="text mt-4">
                  Rubynav delivers beer and wine from all stores. For the safety
                  of both Rubers and customers, all deliveries are now 100%
                  contactless. You will scan your ID and submit a selfie in the
                  app which will be verified by your Rubers prior to delivery.
                  If you are
                </p>
                <p class="text">
                  In most cities, you can order beer and wine from 7am to 11pm
                  Monday through Friday, 7am to 12am on Saturday, and 12pm to
                  11pm on Sunday. However, operation hours vary by market.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">How does Rubynav work?</h2>
                <p class="text mt-4">
                  Go to Rubynav.com or download the Ruby app to browse popular
                  nearby restaurants and Grocery stores, Errands, Gig-Pros or
                  tap the ‘search’ icon to order from any store within your
                  delivery zone. You can add items from our menus or type in
                  custom orders. After you order your Rubynav, it is assigned to
                  one of our Rubers, who will complete your delivery.
                </p>
                <p class="text">
                  Rubers act as your personal assistant that you can text
                  throughout entire delivery, so if you decide you need to make
                  any changes, text your Ruber to let them know.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">How do I pay for a Ruby?</h2>
                <p class="text mt-4">
                  Ruby is completely cashless. All payment is made through our
                  app and we accept all major credit and debit cards. When you
                  order your first Rubynav, you’ll enter your payment
                  information, which we store securely. You only need to enter
                  your payment info once. After you place a Rubynav, we will
                  perform a pre-authorization on your default payment method.
                  Additionally, you will have a short time after it’s been
                  completed to proactively pay. During this time you can add a
                  promo code, change the suggested Ruber tip, and choose which
                  card you’d like to use. After this short time, we will
                  automatically charge your default payment method and the
                  suggested tip.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-10 mx-auto">
      <div
        class="d-flex align-items-center justify-content-between bg-light mt-5 rounded"
        data-toggle="collapse"
        href="#integrations"
        role="button"
        aria-expanded="false"
        aria-controls="multiCollapseExample1"
      >
        <div class="container">
          <div class="d-flex align-items-center justify-content-between">
            <h2 class="backdrop_title">Integrations</h2>
            <img src="../../../../assets/pricing/Vector.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="collapse multi-collapse" id="integrations">
              <div class="mt-5">
                <h2 class="title mt-4">How do I change my phone number?</h2>
                <p class="text mt-4">
                  We would be happy to help you change the phone number on your
                  account. Please email our Support team at
                  accountupdates@rubynav.com with a summary of your request. To
                  expedite the process, please send the email from the address
                  that’s linked to your account.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">
                  What can I have delivered with Rubynav?
                </h2>
                <p class="text mt-4">
                  Almost anything! We deliver food from your favorite
                  restaurants, groceries from your favorite grocery store, any
                  errand of yours that includes prescription pickup, drycleaner,
                  or mail pickup & drop off, and of course also have the right
                  Gig Pros at your doorstep.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">What is Rubynav?</h2>
                <p class="text mt-4">
                  Rubynav is the easiest way to get anything you want in your
                  city delivered to your door in under an hour be it Food,
                  Grocery, quick errands, or gig pros
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">Are you expanding?</h2>
                <p class="text mt-4">
                  We will absolutely be expanding into new cities and areas. We
                  always put customer service first before growing too quickly.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">How can I purchase a Ruby gift card?</h2>
                <p class="text mt-4">
                  Rubynav gift cards are a great way to give the gift of
                  Anything Delivered! To purchase a Ruby gift card, go to
                  Rubynav.com/gift-cards. Choose a design, desired amount ($25
                  minimum, $500 maximum), and your gift card will be sent via
                  email.
                </p>
                <p class="text mt-4">
                  Gift cards can be applied to the entire cost of the order
                  including fees, tip, and the cost of items. You can use
                  Rubynav in over 100 cities across Texas.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="collapse multi-collapse" id="integrations">
              <div class="mt-5">
                <h2 class="title mt-4">Can I have alcohol delivered?</h2>
                <p class="text mt-4">
                  Rubynav delivers beer and wine from all stores. For the safety
                  of both Rubers and customers, all deliveries are now 100%
                  contactless. You will scan your ID and submit a selfie in the
                  app which will be verified by your Rubers prior to delivery.
                  If you are
                </p>
                <p class="text">
                  In most cities, you can order beer and wine from 7am to 11pm
                  Monday through Friday, 7am to 12am on Saturday, and 12pm to
                  11pm on Sunday. However, operation hours vary by market.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">How does Rubynav work?</h2>
                <p class="text mt-4">
                  Go to Rubynav.com or download the Ruby app to browse popular
                  nearby restaurants and Grocery stores, Errands, Gig-Pros or
                  tap the ‘search’ icon to order from any store within your
                  delivery zone. You can add items from our menus or type in
                  custom orders. After you order your Rubynav, it is assigned to
                  one of our Rubers, who will complete your delivery.
                </p>
                <p class="text">
                  Rubers act as your personal assistant that you can text
                  throughout entire delivery, so if you decide you need to make
                  any changes, text your Ruber to let them know.
                </p>
              </div>
              <div class="">
                <h2 class="title mt-4">How do I pay for a Ruby?</h2>
                <p class="text mt-4">
                  Ruby is completely cashless. All payment is made through our
                  app and we accept all major credit and debit cards. When you
                  order your first Rubynav, you’ll enter your payment
                  information, which we store securely. You only need to enter
                  your payment info once. After you place a Rubynav, we will
                  perform a pre-authorization on your default payment method.
                  Additionally, you will have a short time after it’s been
                  completed to proactively pay. During this time you can add a
                  promo code, change the suggested Ruber tip, and choose which
                  card you’d like to use. After this short time, we will
                  automatically charge your default payment method and the
                  suggested tip.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
