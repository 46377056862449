import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as Notiflix from 'notiflix';
import { HoolioAuthService } from 'src/app/shared/services/auth/hoolio-auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent implements OnInit {
  loginForm: FormGroup | any;
  application: string = '';
  type: string = '';
  registerForm: FormGroup | any;
  show: boolean = false;

  constructor(
    private _auth: HoolioAuthService,
    private fb: FormBuilder,
    private _router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.application = this.activatedRoute.snapshot.queryParams.application;
    this.type = this.activatedRoute.snapshot.queryParams.type;

    if (this.application === 'hoolio-invoicing') {
      localStorage.setItem(
        'callback_url',
        'https://staging-api.hoolioinvoicing.com/api/auth/register'
      );
    }
    localStorage.setItem('app_redirect', this.application);
  }

  password() {
    this.show = !this.show;
  }

  ngOnInit(): void {
    this.initializeLoginForm();
    this.initializeRegisterForm();
  }

  initializeLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      password: [''],
      application: this.application,
      callback_url: localStorage.getItem('callback_url'),
    });
  }

  initializeRegisterForm() {
    this.registerForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      password: [''],
    });
  }

  hasError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  };

  hasErrorSignUp = (controlName: string, errorName: string) => {
    return this.registerForm.controls[controlName].hasError(errorName);
  };

  registerUser() {
    Notiflix.Loading.circle('Loading...', {
      backgroundColor: 'rgba(0,0,0,0.6)',
    });
    let form = this.registerForm.value;
    form.password_confirmation = form.password;
    localStorage.setItem('user_email', form.email);
    this._auth.signUp(form).subscribe((res: any) => {
      Notiflix.Loading.remove();
      Notiflix.Notify.success(res.message, {
        useIcon: false,
        timeout: 7000,
      });
      window.location.href = '/onboarding/email-verification';
    });
  }
}
