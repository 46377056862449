<div class="row">
  <div class="col-12 bg pt-5 pb-5">
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="col-md-4">
          <h1 class="title">How it worked</h1>
        </div>
        <div class="col-md-8 custom_margin_top">
          <p class="text">
            Fender is an application that allows users access mechanics for
            repairs and schedule car mainteneace services .This is advantageous
            to car owners because it brings the car repair business right at the
            place where their vehicles fail. The availbility of a mobile
            mechanic enables car owners to save money. This also meanss they no
            longer have to spend time to bring their car into the shop for
            routine checks and tune up services. <br> <br> In Short, Fender helps customers
            get on with their daily schedules, while their cars are being
            checked and repaired.
          </p>
          <div class="mt-5">
              <button routerLink="/login" class="btn btn_dark">Get Started</button>
              <button routerLink="/pricing" class="btn btn_light ml-3">View Pricing</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
