<div class="container">
  <div class="d-flex align-items-center justify-content-between pt-4">
    <div class="logo">
      <a routerLink="/">
        <img
          src="../../../../assets/dashboard/Group 13.svg"
          alt=""
          class="navbar-brand"
        />
      </a>
    </div>

    <div class="d-flex align-items-center ml-5">
      <div class="nav-icon">
        <div class="dropdown">
          <button
            type="button"
            class="border-0"
            id="dropdownMenuOffset"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-offset="0,20"
          >
            <div class="d-flex align-items-center">
              <div class="profile">
                <p class="welcome_text p-0 m-0">Hello, Toby</p>
                <h4 class="name_text">My Account</h4>
              </div>
              <i class="fas fa-angle-down ml-3"></i>
            </div>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuOffset">
            <div class="d-flex align-items-center p-3 mr-4">
              <img src="../../../assets/dashboard/Ellipse 10.svg" alt="" />
              <div class="ml-2">
                <h2 class="backdrop_title mb-0">Sarah Shaibu</h2>
                <p class="backdrop_email mb-0">sarah.shaibu@gmail.com</p>
              </div>
            </div>
            <a class="nav-link mt-3" routerLink="/users">User</a>
            <a class="dropdown-item nav-link" routerLink="/settings"
              >My Account</a
            >
            <a class="nav-link" href="#">Help</a>
            <div class="pl-3 pr-3 pt-4">
              <p class="border-top pt-3">
                <a class="logout"> Log Out </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="profile_pic">
        <img
          src="../../../../assets/dashboard/Ellipse 1.svg"
          alt=""
          class="ml-3 profile_margin"
        />
      </div>
    </div>
  </div>
</div>
