<div class="custom_margin_bottom">
  <div class="d-flex align-items-center custom_margin">
    <ul
      class="nav nav-pills mb-5 mt-5 border-bottom w-100"
      id="pills-tab"
      role="tablist"
    >
      <li class="nav-item" role="presentation">
        <a
          class="nav-link active pl-2 pr-2"
          id="pills-support-tab"
          data-toggle="pill"
          href="#overview"
          role="tab"
          aria-controls="pills-support"
          aria-selected="true"
          >Overview</a
        >
      </li>
      <li class="nav-item ml-4" role="presentation">
        <a
          class="nav-link pl-2 pr-2"
          id="pills-growth-tab"
          data-toggle="pill"
          href="#growth"
          role="tab"
          aria-controls="pills-growth"
          aria-selected="false"
          >Invoices</a
        >
      </li>
      <li class="nav-item ml-4" role="presentation">
        <a
          class="nav-link pl-2 pr-2"
          id="pills-management-tab"
          data-toggle="pill"
          href="#management"
          role="tab"
          aria-controls="pills-management"
          aria-selected="false"
          >Payment Reciepts</a
        >
      </li>
    </ul>
  </div>
  <div class="tab-content" id="pills-tabContent">
    <div
      class="tab-pane fade show active"
      id="overview"
      role="tabpanel"
      aria-labelledby="pills-support-tab"
    >
      <div class="row">
        <div class="col-lg-4">
          <h2 class="title">PAYMENT METHODS</h2>
          <div
            class="d-flex w-100 align-items-center master mt-4 mb-4 justify-content-around"
          >
            <img
              src="../../../../assets/billing_details/Vector.svg"
              class=""
              alt=""
            />
            <img
              src="../../../../assets/settings/image 13.svg"
              class=""
              alt=""
            />
            <p class="card_number mb-0">**** **** **** 4902</p>
            <a
              href=""
              class="link"
              data-toggle="modal"
              data-target="#removePayment"
            >
              <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.031 3.08824H13.8235V2.10376C13.8235 0.943627 12.8799 0 11.7198 0H7.04657C5.88644 0 4.94281 0.943627 4.94281 2.10376V3.08824H0.735294C0.330882 3.08824 0 3.41912 0 3.82353C0 4.22794 0.330882 4.55882 0.735294 4.55882H1.85458V17.4346C1.85458 18.848 3.00245 20 4.41993 20H14.3505C15.7639 20 16.9158 18.8521 16.9158 17.4346V6.91176C16.9158 6.50735 16.585 6.17647 16.1806 6.17647C15.7761 6.17647 15.4453 6.50735 15.4453 6.91176V17.4346C15.4453 18.0392 14.9551 18.5294 14.3505 18.5294H4.41585C3.81127 18.5294 3.32108 18.0392 3.32108 17.4346V4.55882H18.027C18.4355 4.55882 18.7663 4.22794 18.7663 3.82353C18.7663 3.41912 18.4355 3.08824 18.031 3.08824ZM12.357 3.08824H6.4134V2.10376C6.4134 1.75654 6.69526 1.47059 7.04657 1.47059H11.7239C12.0711 1.47059 12.357 1.75245 12.357 2.10376V3.08824Z"
                  fill="#171E40"
                />
                <path
                  d="M9.38324 6.17627C8.97883 6.17627 8.64795 6.50715 8.64795 6.91156V16.1763C8.64795 16.5807 8.97883 16.9116 9.38324 16.9116C9.78766 16.9116 10.1185 16.5807 10.1185 16.1763V6.91156C10.1185 6.50715 9.78766 6.17627 9.38324 6.17627Z"
                  fill="#171E40"
                />
                <path
                  d="M6.29486 6.17627C5.89045 6.17627 5.55957 6.50715 5.55957 6.91156V16.1763C5.55957 16.5807 5.89045 16.9116 6.29486 16.9116C6.69928 16.9116 7.03016 16.5807 7.03016 16.1763V6.91156C7.03016 6.50715 6.69928 6.17627 6.29486 6.17627Z"
                  fill="#171E40"
                />
                <path
                  d="M12.4714 6.17627C12.067 6.17627 11.7361 6.50715 11.7361 6.91156V16.1763C11.7361 16.5807 12.067 16.9116 12.4714 16.9116C12.8758 16.9116 13.2067 16.5807 13.2067 16.1763V6.91156C13.2067 6.50715 12.8758 6.17627 12.4714 6.17627Z"
                  fill="#171E40"
                />
              </svg>
            </a>
          </div>
          <div
            class="d-flex align-items-center vista w-100 justify-content-around"
          >
            <img
              src="../../../../assets/billing_details/image 12.svg"
              class=""
              alt=""
            />
            <p class="card_number mb-0">**** **** **** 4902</p>
            <a
              href=""
              class="link"
              data-toggle="modal"
              data-target="#removePayment"
            >
              <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.031 3.08824H13.8235V2.10376C13.8235 0.943627 12.8799 0 11.7198 0H7.04657C5.88644 0 4.94281 0.943627 4.94281 2.10376V3.08824H0.735294C0.330882 3.08824 0 3.41912 0 3.82353C0 4.22794 0.330882 4.55882 0.735294 4.55882H1.85458V17.4346C1.85458 18.848 3.00245 20 4.41993 20H14.3505C15.7639 20 16.9158 18.8521 16.9158 17.4346V6.91176C16.9158 6.50735 16.585 6.17647 16.1806 6.17647C15.7761 6.17647 15.4453 6.50735 15.4453 6.91176V17.4346C15.4453 18.0392 14.9551 18.5294 14.3505 18.5294H4.41585C3.81127 18.5294 3.32108 18.0392 3.32108 17.4346V4.55882H18.027C18.4355 4.55882 18.7663 4.22794 18.7663 3.82353C18.7663 3.41912 18.4355 3.08824 18.031 3.08824ZM12.357 3.08824H6.4134V2.10376C6.4134 1.75654 6.69526 1.47059 7.04657 1.47059H11.7239C12.0711 1.47059 12.357 1.75245 12.357 2.10376V3.08824Z"
                  fill="#171E40"
                />
                <path
                  d="M9.38324 6.17627C8.97883 6.17627 8.64795 6.50715 8.64795 6.91156V16.1763C8.64795 16.5807 8.97883 16.9116 9.38324 16.9116C9.78766 16.9116 10.1185 16.5807 10.1185 16.1763V6.91156C10.1185 6.50715 9.78766 6.17627 9.38324 6.17627Z"
                  fill="#171E40"
                />
                <path
                  d="M6.29486 6.17627C5.89045 6.17627 5.55957 6.50715 5.55957 6.91156V16.1763C5.55957 16.5807 5.89045 16.9116 6.29486 16.9116C6.69928 16.9116 7.03016 16.5807 7.03016 16.1763V6.91156C7.03016 6.50715 6.69928 6.17627 6.29486 6.17627Z"
                  fill="#171E40"
                />
                <path
                  d="M12.4714 6.17627C12.067 6.17627 11.7361 6.50715 11.7361 6.91156V16.1763C11.7361 16.5807 12.067 16.9116 12.4714 16.9116C12.8758 16.9116 13.2067 16.5807 13.2067 16.1763V6.91156C13.2067 6.50715 12.8758 6.17627 12.4714 6.17627Z"
                  fill="#171E40"
                />
              </svg>
            </a>
          </div>
          <a
            href=""
            class="link"
            data-toggle="modal"
            data-target="#addPaymentModal"
          >
            <button class="btn w-100 custom_btn mt-4">
              Add payment method
            </button>
          </a>
        </div>
        <div class="col-lg-4 custom_margin_top">
          <h2 class="title">Billing Date</h2>
          <h3 class="mb-0 billing_title mt-4">
            Your next billing date is 14 August 2020.
          </h3>
          <button
            class="d-flex w-100 align-items-center master mt-4 mb-4 justify-content-center"
          >
            <img
              src="../../../../assets/settings/image 13.svg"
              class=""
              alt=""
            />
            <p class="card_number mb-0 ml-3">**** **** **** 4902</p>
          </button>
        </div>
        <div class="col-lg-4 custom_margin_top">
          <h2 class="title">Monthly Plan</h2>
          <div class="monthly_plan mt-4">
            <div class="container pt-3">
              <h2 class="monthly_plan_title">Monthly Plan</h2>
              <div
                class="d-flex align-items-center justify-content-between mt-4"
              >
                <p class="mb-0 plan_text">$25/month</p>
                <a
                  href=""
                  class="link"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  >change plan</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ADD PAYMENT METHOD MODAL -->
      <div
        class="modal fade"
        id="addPaymentModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable"
        >
          <div class="modal-content p-4">
            <div class="container">
              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  class="custom_close mt-3"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true"
                    ><svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.2127 16.75C14.8028 16.75 14.4441 16.5963 14.1366 16.2888L0.71118 2.86335C0.0962733 2.24845 0.0962733 1.27484 0.71118 0.71118C1.32609 0.0962733 2.29969 0.0962733 2.86335 0.71118L16.2888 14.0854C16.9037 14.7003 16.9037 15.6739 16.2888 16.2376C15.9814 16.5963 15.5714 16.75 15.2127 16.75Z"
                        fill="#003456"
                      />
                      <path
                        d="M1.78727 16.75C1.37733 16.75 1.01863 16.5963 0.71118 16.2888C0.0962733 15.6739 0.0962733 14.7003 0.71118 14.1366L14.0854 0.71118C14.7003 0.0962733 15.6739 0.0962733 16.2376 0.71118C16.8525 1.32609 16.8525 2.29969 16.2376 2.86335L2.86335 16.2888C2.5559 16.5963 2.19721 16.75 1.78727 16.75Z"
                        fill="#003456"
                      /></svg
                  ></span>
                </button>
              </div>
              <h5 class="modal-title" id="exampleModalLabel">
                Add Payment Method
              </h5>
            </div>
            <div class="modal-body mt-4">
              <div class="d-flex">
                <button class="card_btn mr-2">
                  <img
                    src="../../../assets/billing_details/14-ebay.png"
                    class="mr-3 ml-3"
                    alt=""
                  />
                </button>
                <button class="card_btn">
                  <img
                    src="../../../assets/billing_details/014-ebay.png"
                    class="ml-3 mr-3"
                    alt=""
                  />
                </button>
              </div>

              <div class="text-center mt-5">
                <h3 class="sub_headers d-flex align-items-start">
                  Card Number
                </h3>
                <div
                  class="d-flex align-items-center card_border justify-content-between"
                >
                  <input
                    class="card_digit"
                    type="text"
                    name=""
                    placeholder="Input Visa Card Numbers"
                    id=""
                  />
                  <i class="far fa-credit-card"></i>
                </div>
                <div class="row">
                  <div class="col-6 mt-3">
                    <h3 class="sub_headers d-flex align-items-start">
                      Exp Date
                    </h3>
                    <div
                      class="d-flex align-items-center card_border justify-content-between"
                    >
                      <input
                        class="cvv_code"
                        type="text"
                        name=""
                        placeholder="00/00/00"
                        id=""
                      />
                      <i class="far fa-credit-card"></i>
                    </div>
                  </div>
                  <div class="col-6 mt-3">
                    <h3 class="sub_headers d-flex align-items-start">
                      CVV Code
                    </h3>
                    <div
                      class="d-flex align-items-center card_border justify-content-between"
                    >
                      <input
                        class="cvv_code"
                        type="text"
                        name=""
                        placeholder="Code"
                        id=""
                      />
                      <i class="far fa-credit-card"></i>
                    </div>
                  </div>
                </div>
                <button
                  class="dark_btn w-100 mt-4 d-flex justify-content-center"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- REMOVE PAYMENT METHOD MODAL -->
      <div
        class="modal fade"
        id="removePayment"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable"
        >
          <div class="modal-content p-4 p-2">
            <div class="container">
              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  class="custom_close mt-3"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true"
                    ><svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.2127 16.75C14.8028 16.75 14.4441 16.5963 14.1366 16.2888L0.71118 2.86335C0.0962733 2.24845 0.0962733 1.27484 0.71118 0.71118C1.32609 0.0962733 2.29969 0.0962733 2.86335 0.71118L16.2888 14.0854C16.9037 14.7003 16.9037 15.6739 16.2888 16.2376C15.9814 16.5963 15.5714 16.75 15.2127 16.75Z"
                        fill="#003456"
                      />
                      <path
                        d="M1.78727 16.75C1.37733 16.75 1.01863 16.5963 0.71118 16.2888C0.0962733 15.6739 0.0962733 14.7003 0.71118 14.1366L14.0854 0.71118C14.7003 0.0962733 15.6739 0.0962733 16.2376 0.71118C16.8525 1.32609 16.8525 2.29969 16.2376 2.86335L2.86335 16.2888C2.5559 16.5963 2.19721 16.75 1.78727 16.75Z"
                        fill="#003456"
                      /></svg
                  ></span>
                </button>
              </div>
              <h5 class="modal-title" id="exampleModalLabel">
                Remove Payment Method
              </h5>
            </div>
            <div class="modal-body mt-4">
              <button
                class="d-flex w-100 align-items-center master mb-4 justify-content-center"
              >
                <img
                  src="../../../../assets/settings/image 13.svg"
                  class=""
                  alt=""
                />
                <p class="card_number mb-0 ml-3">**** **** **** 4902</p>
              </button>

              <div class="text-center mt-3">
                <h3 class="sub_header text-center">
                  Remove this payment method?
                </h3>
                <p class="text text-center">
                  This action will delete this pament method as an option from
                  our servers
                </p>
                <button
                  class="dark_btn w-100 mt-5 d-flex justify-content-center"
                >
                  Change plan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="growth"
      role="tabpanel"
      aria-labelledby="pills-growth-tab"
    >
      <div class="">
        <div class="row">
          <div class="col-md-6">
            <div class="container p-0">
              <div class="plan_invoice pl-3 pr-3">
                <div class="mb-4">
                  <h2 class="invoice_title pt-4 mb-4">Plan Invoices</h2>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">August</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#downloadComplete"
                      >
                        <img
                          src="../../../../assets/invoice/Download.png"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#invoiceMP"
                      >
                        <img
                          src="../../../../assets/invoice/Shape.svg"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">August</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#downloadComplete"
                      >
                        <img
                          src="../../../../assets/invoice/Download.png"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#invoiceMP"
                      >
                        <img
                          src="../../../../assets/invoice/Shape.svg"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">August</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#downloadComplete"
                      >
                        <img
                          src="../../../../assets/invoice/Download.png"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#invoiceMP"
                      >
                        <img
                          src="../../../../assets/invoice/Shape.svg"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">August</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#downloadComplete"
                      >
                        <img
                          src="../../../../assets/invoice/Download.png"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#invoiceMP"
                      >
                        <img
                          src="../../../../assets/invoice/Shape.svg"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">August</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#downloadComplete"
                      >
                        <img
                          src="../../../../assets/invoice/Download.png"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#invoiceMP"
                      >
                        <img
                          src="../../../../assets/invoice/Shape.svg"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 sm-padding custom_margin_top d-flex align-items-center"
          >
            <div class="container p-0">
              <div class="plan_invoice pl-3 pr-3">
                <div class="mb-4">
                  <h2 class="invoice_title pt-4 mb-4">Pay Per Use Invoices</h2>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2 text-left"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">August</span>
                      <span class="invoice_plan ml-4">Chat, Call</span>
                      <span class="invoice_amount ml-4">$145</span>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#downloadComplete"
                      >
                        <img
                          src="../../../../assets/invoice/Download.png"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#invoicePPS"
                      >
                        <img
                          src="../../../../assets/invoice/Shape.svg"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2 text-left"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">August</span>
                      <span class="invoice_plan ml-4">Chat, Call</span>
                      <span class="invoice_amount ml-4">$145</span>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#downloadComplete"
                      >
                        <img
                          src="../../../../assets/invoice/Download.png"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#invoicePPS"
                      >
                        <img
                          src="../../../../assets/invoice/Shape.svg"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2 text-left"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">August</span>
                      <span class="invoice_plan ml-4">Chat, Call</span>
                      <span class="invoice_amount ml-4">$145</span>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#downloadComplete"
                      >
                        <img
                          src="../../../../assets/invoice/Download.png"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#invoicePPS"
                      >
                        <img
                          src="../../../../assets/invoice/Shape.svg"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2 text-left"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">August</span>
                      <span class="invoice_plan ml-4">Chat, Call</span>
                      <span class="invoice_amount ml-4">$145</span>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#downloadComplete"
                      >
                        <img
                          src="../../../../assets/invoice/Download.png"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#invoicePPS"
                      >
                        <img
                          src="../../../../assets/invoice/Shape.svg"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2 text-left"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">August</span>
                      <span class="invoice_plan ml-4">Chat, Call</span>
                      <span class="invoice_amount ml-4">$145</span>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#downloadComplete"
                      >
                        <img
                          src="../../../../assets/invoice/Download.png"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                      <a
                        href=""
                        class="link"
                        data-toggle="modal"
                        data-target="#invoicePPS"
                      >
                        <img
                          src="../../../../assets/invoice/Shape.svg"
                          class="ml-4"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- INVOICE45 MODAL PAY PER SERVICES-->
          <div
            class="modal fade"
            id="invoicePPS"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable"
            >
              <div class="modal-content p-4 p-2">
                <div class="container">
                  <div class="d-flex justify-content-end">
                    <button
                      type="button"
                      class="custom_close mt-3"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"
                        ><svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.2127 16.75C14.8028 16.75 14.4441 16.5963 14.1366 16.2888L0.71118 2.86335C0.0962733 2.24845 0.0962733 1.27484 0.71118 0.71118C1.32609 0.0962733 2.29969 0.0962733 2.86335 0.71118L16.2888 14.0854C16.9037 14.7003 16.9037 15.6739 16.2888 16.2376C15.9814 16.5963 15.5714 16.75 15.2127 16.75Z"
                            fill="#003456"
                          />
                          <path
                            d="M1.78727 16.75C1.37733 16.75 1.01863 16.5963 0.71118 16.2888C0.0962733 15.6739 0.0962733 14.7003 0.71118 14.1366L14.0854 0.71118C14.7003 0.0962733 15.6739 0.0962733 16.2376 0.71118C16.8525 1.32609 16.8525 2.29969 16.2376 2.86335L2.86335 16.2888C2.5559 16.5963 2.19721 16.75 1.78727 16.75Z"
                            fill="#003456"
                          /></svg
                      ></span>
                    </button>
                  </div>
                  <h5 class="modal-title" id="exampleModalLabel">
                    Invoice- #45
                  </h5>
                </div>
                <div class="modal-body mt-3">
                  <p class="invoice_modal_title">Billed on 14th August 2020</p>

                  <div class="mt-4">
                    <p class="invoice_modal_title">
                      Pay Per Service Charge Breakdown
                    </p>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p class="modal_text">Chat</p>
                      <p class="modal_text">$100</p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p class="modal_text">Call</p>
                      <p class="modal_text">$150</p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p class="modal_total_text">Total</p>
                      <p class="modal_total_text">$250</p>
                    </div>
                  </div>

                  <div class="mt-5">
                    <p class="modal_tech_title">
                      Charge Made by Hoolio Technologies
                    </p>
                    <p class="modal_tech_text">
                      If you have an issue over a charge please contact
                      support@hoolio.io
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- INVOICE45 MODAL MONTHLY PLAN-->
          <div
            class="modal fade"
            id="invoiceMP"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable"
            >
              <div class="modal-content p-4 p-2">
                <div class="container">
                  <div class="d-flex justify-content-end">
                    <button
                      type="button"
                      class="custom_close mt-3"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"
                        ><svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.2127 16.75C14.8028 16.75 14.4441 16.5963 14.1366 16.2888L0.71118 2.86335C0.0962733 2.24845 0.0962733 1.27484 0.71118 0.71118C1.32609 0.0962733 2.29969 0.0962733 2.86335 0.71118L16.2888 14.0854C16.9037 14.7003 16.9037 15.6739 16.2888 16.2376C15.9814 16.5963 15.5714 16.75 15.2127 16.75Z"
                            fill="#003456"
                          />
                          <path
                            d="M1.78727 16.75C1.37733 16.75 1.01863 16.5963 0.71118 16.2888C0.0962733 15.6739 0.0962733 14.7003 0.71118 14.1366L14.0854 0.71118C14.7003 0.0962733 15.6739 0.0962733 16.2376 0.71118C16.8525 1.32609 16.8525 2.29969 16.2376 2.86335L2.86335 16.2888C2.5559 16.5963 2.19721 16.75 1.78727 16.75Z"
                            fill="#003456"
                          /></svg
                      ></span>
                    </button>
                  </div>
                  <h5 class="modal-title" id="exampleModalLabel">
                    Invoice- #45
                  </h5>
                </div>
                <div class="modal-body mt-3">
                  <p class="invoice_modal_title">Billed on 14th August 2020</p>

                  <div class="mt-4 mb-3">
                    <p class="invoice_modal_title">Monthly Plan</p>
                    <div
                      class="d-flex align-items-center justify-content-between mb-5 pb-5"
                    >
                      <p class="modal_total_text">Total</p>
                      <p class="modal_total_text">$25</p>
                    </div>
                  </div>

                  <div class="mt-5">
                    <p class="modal_tech_title">
                      Charge Made by Hoolio Technologies
                    </p>
                    <p class="modal_tech_text">
                      If you have an issue over a charge please contact
                      support@hoolio.io
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- DOWNLOAD COMPLETE MODAL-->
          <div
            class="modal fade"
            id="downloadComplete"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-md">
              <div class="modal-content download-content p-2">
                <div class="">
                  <div class="container">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div class="">
                        <h2 class="download_title mt-3">Download Complete</h2>
                        <p class="download_text">Thanks for using hoolio</p>
                      </div>
                      <button
                        class="cancel_btn close"
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        X
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="management"
      role="tabpanel"
      aria-labelledby="pills-management-tab"
    >
      <div class="">
        <div class="row">
          <div class="col-md-6">
            <div class="container p-0">
              <div class="plan_invoice pl-3 pr-3">
                <div class="mb-4">
                  <h2 class="invoice_title pt-4 mb-4">Plan Invoices</h2>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 sm-padding custom_margin_top d-flex align-items-center"
          >
            <div class="container p-0">
              <div class="plan_invoice pl-3 pr-3">
                <div class="mb-4">
                  <h2 class="invoice_title pt-4 mb-4">Pay Per Use Invoices</h2>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Chat, Call</span>
                      <span class="invoice_amount ml-4">$15</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Chat, Call</span>
                      <span class="invoice_amount ml-4">$15</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Chat, Call</span>
                      <span class="invoice_amount ml-4">$15</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Chat, Call</span>
                      <span class="invoice_amount ml-4">$15</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Chat, Call</span>
                      <span class="invoice_amount ml-4">$15</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content p-4 p-2">
        <div class="container">
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="custom_close mt-3"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.2127 16.75C14.8028 16.75 14.4441 16.5963 14.1366 16.2888L0.71118 2.86335C0.0962733 2.24845 0.0962733 1.27484 0.71118 0.71118C1.32609 0.0962733 2.29969 0.0962733 2.86335 0.71118L16.2888 14.0854C16.9037 14.7003 16.9037 15.6739 16.2888 16.2376C15.9814 16.5963 15.5714 16.75 15.2127 16.75Z"
                    fill="#003456"
                  />
                  <path
                    d="M1.78727 16.75C1.37733 16.75 1.01863 16.5963 0.71118 16.2888C0.0962733 15.6739 0.0962733 14.7003 0.71118 14.1366L14.0854 0.71118C14.7003 0.0962733 15.6739 0.0962733 16.2376 0.71118C16.8525 1.32609 16.8525 2.29969 16.2376 2.86335L2.86335 16.2888C2.5559 16.5963 2.19721 16.75 1.78727 16.75Z"
                    fill="#003456"
                  /></svg
              ></span>
            </button>
          </div>
          <h5 class="modal-title" id="exampleModalLabel">Change Plan</h5>
        </div>
        <div class="modal-body mt-4">
          <button class="dark_btn w-100">
            <img
              src="../../../../assets/settings/tick.svg"
              class="mr-3 ml-3"
              alt=""
            />
            $25/month
          </button>
          <button class="light_btn w-100 mt-4">
            <img
              src="../../../assets/billing_details/Vector (1).svg"
              class="ml-3 mr-3"
              alt=""
            />
            $150/year
          </button>

          <div class="text-center mt-5">
            <h3 class="sub_header">Select this plan?</h3>
            <p class="text">
              This will change your current plan, click change plan to proceed
            </p>
            <button class="dark_btn w-100 mt-4 d-flex justify-content-center">
              Change plan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
