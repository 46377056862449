<div class="container">
  <div class="row mt-4">
    <div class="col-md-5 d-flex align-items-center">
      <div class="">
        <h1 class="hero_title">Elevate your workflow</h1>
        <p class="hero_text mt-5 mb-5">
          Meet Hoolio, the application suite built for unified communication,
          team collaboration and productivity.
        </p>
        <button routerLink="/login" class="btn custom_btn_dark">Get Started</button>
        <button class="btn custom_btn_light ml-3">Watch the video</button>
      </div>
    </div>
    <div class="col-md-7 d-flex align-items-center sm-header-padding">
      <img
        src="../../../../assets/homepage/header-bg.svg"
        alt=""
        class="card-img"
      />
    </div>
  </div>
</div>
