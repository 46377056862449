import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as Notiflix from 'notiflix';
import { HoolioAuthService } from 'src/app/shared/services/auth/hoolio-auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup | any;
  application: string = '';
  show: boolean = false;
  type: any = '';

  constructor(
    private _auth: HoolioAuthService,
    private fb: FormBuilder,
    private _router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.application = this.activatedRoute.snapshot.queryParams.application;
    this.type = this.activatedRoute.snapshot.queryParams.type;
    if (this.application === 'hoolio-invoicing') {
      localStorage.setItem(
        'callback_url',
        'https://staging-api.hoolioinvoicing.com/api/auth/register'
      );
    }
    if (this.application === 'hoolio-learn') {
      localStorage.setItem(
        'callback_url',
        'https://staging-api.hooliolearn.com/api/auth/register'
      );
    }
    localStorage.setItem('app_redirect', this.application);
  }

  password() {
    this.show = !this.show;
  }

  ngOnInit(): void {
    this.initializeLoginForm();
  }

  initializeLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      password: [''],
      application: this.application,
      callback_url: localStorage.getItem('callback_url'),
    });
  }

  hasError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  };

  loginUser() {
    Notiflix.Loading.circle('Loading...', {
      backgroundColor: 'rgba(0,0,0,0.6)',
    });
    let form = this.loginForm.value;
    localStorage.setItem('user_email', form.email);
    this._auth.login(form).subscribe((res: any) => {
      switch (form.application) {
        case 'hoolio-invoicing':
          if (res?.data?.user['is_verified'] === 0) {
            window.location.href = '/onboarding/email-verification';
          } else {
            window.location.href = `${environment.env_redirect}/?token=${res.data.token}`;
          }
          Notiflix.Loading.remove();
          break;
      }
    });
  }
}
