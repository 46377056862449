import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HoolioAuthService } from '../services/auth/hoolio-auth.service';
import * as Notiflix from 'notiflix';

@Injectable({
  providedIn: 'root',
})
export class InterceptorsService {
  constructor(private _auth: HoolioAuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (window.navigator.onLine) {
      return next.handle(request).pipe(
        tap((data: any) => {}),
        catchError((error: any) => {
          Notiflix.Loading.remove();
          if (error.status == 422) {
           error.error.message.forEach((error: any) => {
             Notiflix.Notify.failure(error, {
               timeout: 7000,
               useIcon: false,
             });
           });
          } else {
            error.error.message.forEach((error: any) => {
              Notiflix.Notify.failure(error, {
                timeout: 7000,
                useIcon: false,
              });
            });
          }
          return throwError(error);
        })
      );
    } else {
      Notiflix.Loading.remove();
      Notiflix.Report.warning(
        'No Internet',
        'You are not connected to the internet, check your connection and try again',
        'CLOSE'
      );
      return throwError({ message: 'You are not connected to the internet' });
    }
  }
}
