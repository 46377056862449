<div class="wrapper">
  <div class="row">
    <div class="col-12 mt-5 custom_border_bottom">
      <div class="container pb-3">
        <div class="btn-group">
          <button
            type="button"
            class="btn bg dropdown-toggle"
            data-toggle="dropdown"
            data-display="static"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Industry
          </button>
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left">
            <button class="dropdown-item" type="button">Action</button>
            <button class="dropdown-item" type="button">Another action</button>
            <button class="dropdown-item" type="button">
              Something else here
            </button>
          </div>
        </div>
        <div class="btn-group ml-3">
          <button
            type="button"
            class="btn bg dropdown-toggle"
            data-toggle="dropdown"
            data-display="static"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Region
          </button>
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left">
            <button class="dropdown-item" type="button">Action</button>
            <button class="dropdown-item" type="button">Another action</button>
            <button class="dropdown-item" type="button">
              Something else here
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-5">
      <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 sm-p-4">
          <a routerLink="/customer-stories-details">
            <img
              src="../../../../assets/customer-stories/Rectangle 141.svg"
              alt=""
              class="card-img"
            />
            <h2 class="title">Hotelo</h2>
            <p class="text mb-4">
              Hotelo + Hoolio: Using the voice of customers to create
            </p>
          </a>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 sm-p-4">
          <a routerLink="/customer-stories-details">
            <img
              src="../../../../assets/customer-stories/Rectangle 142.svg"
              alt=""
              class="card-img"
            />
            <h2 class="title">Slate.ng</h2>
            <p class="text mb-4">
              Slate + Hoolio: Using the voice of customers to create
            </p>
          </a>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 sm-p-4">
          <a routerLink="/customer-stories-details">
            <img
              src="../../../../assets/customer-stories/Rectangle 143.svg"
              alt=""
              class="card-img"
            />
            <h2 class="title">Winns Couture</h2>
            <p class="text mb-4">
              WC + Hoolio: Using the voice of customers to create
            </p>
          </a>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 sm-p-4">
          <a routerLink="/customer-stories-details">
            <img
              src="../../../../assets/customer-stories/Rectangle 144.svg"
              alt=""
              class="card-img"
            />
            <h2 class="title">Dropster</h2>
            <p class="text mb-4">
              Dropster + Hoolio: Using the voice of customers to create
            </p>
          </a>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 sm-p-4">
          <a routerLink="/customer-stories-details">
            <img
              src="../../../../assets/customer-stories/Rectangle 141.svg"
              alt=""
              class="card-img"
            />
            <h2 class="title">Hotelo</h2>
            <p class="text mb-4">
              Hotelo + Hoolio: Using the voice of customers to create
            </p>
          </a>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 sm-p-4">
          <a routerLink="/customer-stories-details">
            <img
              src="../../../../assets/customer-stories/Rectangle 142.svg"
              alt=""
              class="card-img"
            />
            <h2 class="title">Slate.ng</h2>
            <p class="text mb-4">
              Slate + Hoolio: Using the voice of customers to create
            </p>
          </a>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 sm-p-4">
          <a routerLink="/customer-stories-details">
            <img
              src="../../../../assets/customer-stories/Rectangle 143.svg"
              alt=""
              class="card-img"
            />
            <h2 class="title">Winns Couture</h2>
            <p class="text mb-4">
              WC + Hoolio: Using the voice of customers to create
            </p>
          </a>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 sm-p-4">
          <a routerLink="/customer-stories-details">
            <img
              src="../../../../assets/customer-stories/Rectangle 144.svg"
              alt=""
              class="card-img"
            />
            <h2 class="title">Dropster</h2>
            <p class="text mb-4">
              Dropster + Hoolio: Using the voice of customers to create
            </p>
          </a>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 sm-p-4">
          <a routerLink="/customer-stories-details">
            <img
              src="../../../../assets/customer-stories/Rectangle 141.svg"
              alt=""
              class="card-img"
            />
            <h2 class="title">Hotelo</h2>
            <p class="text mb-4">
              Hotelo + Hoolio: Using the voice of customers to create
            </p>
          </a>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 sm-p-4">
          <a routerLink="/customer-stories-details">
            <img
              src="../../../../assets/customer-stories/Rectangle 142.svg"
              alt=""
              class="card-img"
            />
            <h2 class="title">Slate.ng</h2>
            <p class="text mb-4">
              Slate + Hoolio: Using the voice of customers to create
            </p>
          </a>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 sm-p-4">
          <a routerLink="/customer-stories-details">
            <img
              src="../../../../assets/customer-stories/Rectangle 143.svg"
              alt=""
              class="card-img"
            />
            <h2 class="title">Winns Couture</h2>
            <p class="text mb-4">
              WC + Hoolio: Using the voice of customers to create
            </p>
          </a>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 sm-p-4">
          <a routerLink="/customer-stories-details">
            <img
              src="../../../../assets/customer-stories/Rectangle 144.svg"
              alt=""
              class="card-img"
            />
            <h2 class="title">Dropster</h2>
            <p class="text mb-4">
              Dropster + Hoolio: Using the voice of customers to create
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
