<div class="row">
  <div class="col-12 mt-5 bg">
    <div class="container mt-5 pb-4">
      <h2 class="title_header pb-3">Applications used</h2>
      <div
        class="d-flex align-items-center justify-content-between bg-white mb-5 p-4"
      >
        <div class="d-flex align-items-start">
          <img src="../../../../assets/homepage/feedback.svg" alt="" />
          <div class="ml-2">
            <h2 class="app_title">Feedback</h2>
            <p class="app_text">Integrated customer feedback</p>
          </div>
        </div>
        <div class="d-flex align-items-start ml-5">
          <img src="../../../../assets/homepage/report.svg" alt="" />
          <div class="ml-2">
            <h2 class="app_title">Report</h2>
            <p class="app_text">Analytics and reporting</p>
          </div>
        </div>
        <div class="d-flex align-items-start ml-5">
          <img src="../../../../assets/homepage/users.svg" alt="" />
          <div class="ml-2">
            <h2 class="app_title">Users</h2>
            <p class="app_text">Manage your users</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="container mt-5 pt-5 mb-5 pb-5">
      <div class="row">
        <div class="col-md-6 d-flex align-items-center">
          <img
            src="../../../../assets/stories-details/Screenshot 2020-08-14 at 10.17 1.svg"
            alt=""
            class="card-img"
          />
        </div>
        <div class="col-md-6 custom_margin_top d-flex align-items-center">
          <div class="">
            <h3 class="sub_header">Support</h3>
            <h1 class="title">Provide support across channels</h1>
            <p class="text mt-4">
              The Support Suite allows your business to have natural
              conversations with your customers, without letting them feel
              what’s going on behind the scenes. It’s sophisticated enough to
              meet complex needs, and simple enough to let you get up and
              running immediately.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
