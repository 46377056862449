<div class="wrapper">
  <div class="container custom_margin">
    <div class="row">
      <div class="col-12">
        <h2 class="title">We are always open 24/7 for you.</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <form>
          <div class="form-row">
            <div class="col-12 mt-4">
              <input
                type="text"
                class="form-control"
                placeholder="Your name"
                required
              />
            </div>
            <div class="col-12 mt-4">
              <input
                type="email"
                class="form-control"
                placeholder="Email address"
                required
              />
            </div>
            <div class="col-12 mt-4">
              <input
                type="text"
                class="form-control"
                placeholder="Subject"
                required
              />
            </div>
            <div class="col-12 mt-4">
              <textarea
                class="form-control height"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="Write your message"
                required
              ></textarea>
            </div>
            <div class="col-12 mt-4">
              <button type="submit" class="submit btn">Send Message</button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-5 d-flex justify-content-center">
        <div class="container">
          <div class="">
            <h2 class="contact_header pt-4">Call us</h2>
            <p class="contact_text">+1-492-4918-395</p>
            <p class="contact_text">+14-394-409-591</p>
          </div>
          <div class="">
            <h2 class="contact_header pt-4">Email us</h2>
            <p class="contact_text">info@mail.com</p>
            <p class="contact_text">support@mail.com</p>
          </div>
          <div class="">
            <h2 class="contact_header pt-4">Visit us</h2>
            <p class="contact_text">34 Madison Street,</p>
            <p class="contact_text">NY, USA 10005</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
