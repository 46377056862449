import { Component, OnInit } from '@angular/core';
import * as Notiflix from 'notiflix';
import { HoolioAuthService } from 'src/app/shared/services/auth/hoolio-auth.service';

@Component({
  selector: 'app-email-component',
  templateUrl: './email-component.component.html',
  styleUrls: ['./email-component.component.css'],
})
export class EmailComponentComponent implements OnInit {
  code: any;

  constructor(private _auth: HoolioAuthService) {}

  ngOnInit(): void {}

  onOtpChange(event: any) {
    this.code = event;
  }

  onSubmit() {
    Notiflix.Loading.circle('Loading...', {
      backgroundColor: 'rgba(0,0,0,0.6)',
    });
    if (this.code?.length < 4) {
      Notiflix.Notify.failure('Enter correct OTP', {
        useIcon: false,
      });
    } else if (!this.code) {
      Notiflix.Notify.failure('Enter the OTP sent to your mail', {
        useIcon: false,
      });
    } else {
      let payload = {
        code: this.code,
        email: localStorage.getItem('user_email'),
      };
      this._auth.verifyEmail(payload).subscribe(
        (response: any) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.success(response.message, {
            useIcon: false,
          });
          localStorage.removeItem('user_email');
          window.location.href = `/onboarding/login?application=${localStorage.getItem(
            'app_redirect'
          )}`;
        },
        (error) => {
          Notiflix.Loading.remove();
        }
      );
    }
  }

  resendCode() {
    Notiflix.Loading.circle('Loading...', {
      backgroundColor: 'rgba(0,0,0,0.6)',
    });
    let payload = {
      email: localStorage.getItem('user_email'),
    };
    this._auth.resendCode(payload).subscribe(
      (response: any) => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success(response.message, {
          useIcon: false,
        });
      },
      (error) => {
        Notiflix.Loading.remove();
      }
    );
  }
}
