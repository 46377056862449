<div class="wrapper">
  <div class="my-profile pb-5">
    <app-dashboard-nav></app-dashboard-nav>
    <div class="container">
      <div class="header mt-5">
        <a routerLink="/dashboard" class="dashboard">
          <img
            src="../../../assets/stories-details/arrow-right.svg"
            class="mr-2"
            alt=""
          />
          <span class="my_profile_link"> Dashboard: </span>
        </a>
        <span class="pt-3"> Users </span>
        <h1 class="mt-4 title">Users</h1>
      </div>
      <div class="container mt-4">
        <div class="row bg-white">
          <div class="col-md-6">
            <div class="p-0">
              <div class="plan_invoice pl-3 pr-3">
                <div class="container scroll">
                  <h2 class="invoice_title pt-4 mb-4">User List</h2>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pl-2 pr-2 pt-4"
                    >
                      <span class="invoice_text">Sarah Shaibu</span>
                      <span class="invoice_month ml-4">snefisat</span>
                      <span class="invoice_plan ml-4"></span>
                      <span class="invoice_amount ml-4">$25</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="hoolio_hover">
                    <div
                      class="d-flex align-items-center justify-content-between pb-4 pt-4 pl-2 pr-2"
                    >
                      <span class="invoice_text">Invoice</span>
                      <span class="invoice_month ml-4">April</span>
                      <span class="invoice_plan ml-4">Plan</span>
                      <span class="invoice_amount ml-4">$25</span>
                      <img
                        src="../../../../assets/invoice/Download.png"
                        class="ml-4"
                        alt=""
                      />
                      <img
                        src="../../../../assets/invoice/Shape.svg"
                        class="ml-4"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
