<div class="container custom_margin_bottom">
  <div class="d-flex align-items-center justify-content-center custom_margin">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          class="nav-link active pl-2 pr-2"
          id="pills-support-tab"
          data-toggle="pill"
          href="#support"
          role="tab"
          aria-controls="pills-support"
          aria-selected="true"
          >SUPPORT</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link pl-2 pr-2"
          id="pills-growth-tab"
          data-toggle="pill"
          href="#growth"
          role="tab"
          aria-controls="pills-growth"
          aria-selected="false"
          >GROWTH</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link pl-2 pr-2"
          id="pills-management-tab"
          data-toggle="pill"
          href="#management"
          role="tab"
          aria-controls="pills-management"
          aria-selected="false"
          >MANAGEMENT</a
        >
      </li>
    </ul>
  </div>
  <div class="tab-content" id="pills-tabContent">
    <div
      class="tab-pane fade show active"
      id="support"
      role="tabpanel"
      aria-labelledby="pills-support-tab"
    >
      <div class="container">
        <div class="row mt-5">
          <div class="col-lg-5 d-flex align-items-center">
            <div class="">
              <h2 class="support_header">Support</h2>
              <h1 class="title">Provide support across channels</h1>
              <p class="text mt-4">
                The Support Suite allows your business to have natural
                conversations with your customers, without letting them feel
                what’s going on behind the scenes. It’s sophisticated enough to
                meet complex needs, and simple enough to let you get up and
                running immediately.
              </p>
              <a href="#" class="link_text">Learn more</a>
              <a href="#" class="link_text ml-4">Watch Video</a>

              <h2 class="empower_title mt-5 pt-4">EMPOWER YOUR BUSINESSS</h2>
              <p class="text">
                The Support Suite allows your business to have natural
                conversations with your customers, without letting them feel
                what’s going on behind the scenes. It’s sophisticated enough to
                meet complex needs, and simple enough to let you get up and
                running immediately.
              </p>
              <a href="#" class="link_text">Learn more</a>
            </div>
          </div>
          <div class="col-lg-7 sm-padding d-flex align-items-center">
            <img
              src="../../../../assets/homepage/image-1.svg"
              alt=""
              class="card-img"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="growth"
      role="tabpanel"
      aria-labelledby="pills-growth-tab"
    >
      <div class="container">
        <div class="row mt-5">
          <div class="col-lg-7 d-flex align-items-center">
            <img
              src="../../../../assets/homepage/image-1.svg"
              alt=""
              class="card-img"
            />
          </div>
          <div class="col-lg-5 sm-padding d-flex align-items-center">
            <div class="">
              <h2 class="support_header">Growth</h2>
              <h1 class="title">Provide support across channels</h1>
              <p class="text mt-4">
                The Support Suite allows your business to have natural
                conversations with your customers, without letting them feel
                what’s going on behind the scenes. It’s sophisticated enough to
                meet complex needs, and simple enough to let you get up and
                running immediately.
              </p>
              <a href="#" class="link_text">Learn more</a>
              <a href="#" class="link_text ml-4">Watch Video</a>

              <h2 class="empower_title mt-5 pt-4">EMPOWER YOUR BUSINESSS</h2>
              <p class="text">
                The Support Suite allows your business to have natural
                conversations with your customers, without letting them feel
                what’s going on behind the scenes. It’s sophisticated enough to
                meet complex needs, and simple enough to let you get up and
                running immediately.
              </p>
              <a href="#" class="link_text">Learn more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="management"
      role="tabpanel"
      aria-labelledby="pills-management-tab"
    >
      <div class="container">
        <div class="row mt-5">
          <div class="col-lg-5 d-flex align-items-center">
            <div class="">
              <h2 class="support_header">Management</h2>
              <h1 class="title">Provide support across channels</h1>
              <p class="text mt-4">
                The Support Suite allows your business to have natural
                conversations with your customers, without letting them feel
                what’s going on behind the scenes. It’s sophisticated enough to
                meet complex needs, and simple enough to let you get up and
                running immediately.
              </p>
              <a href="#" class="link_text">Learn more</a>
              <a href="#" class="link_text ml-4">Watch Video</a>

              <h2 class="empower_title mt-5 pt-4">EMPOWER YOUR BUSINESSS</h2>
              <p class="text">
                The Support Suite allows your business to have natural
                conversations with your customers, without letting them feel
                what’s going on behind the scenes. It’s sophisticated enough to
                meet complex needs, and simple enough to let you get up and
                running immediately.
              </p>
              <a href="#" class="link_text">Learn more</a>
            </div>
          </div>
          <div class="col-lg-7 sm-padding d-flex align-items-center">
            <img
              src="../../../../assets/homepage/image-1.svg"
              alt=""
              class="card-img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
