<div class="container">
  <h1 class="nav-link active text-center">Password Recovery</h1>
  <p class="mt-4 pb-4 sub_text text-center">
    let’s help you get your account back
  </p>
  <div [formGroup]="emailForm">
    <div
      class="input_field d-flex align-items-center mt-4 justify-content-between pl-3 pr-3"
    >
      <input
        type="email"
        class="input_area"
        placeholder="Email address"
        formControlName="email"
      /><i class="far fa-envelope font"></i>
    </div>
    <button (click)="submit()" class="btn w-100 btn_login mt-5">Submit</button>
  </div>
</div>
