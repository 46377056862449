<div class="bg svg">
  <div class="container">
    <a href="http://" target="_blank" rel="noopener noreferrer">
      <img
        src="../../../assets/authentication/login-logo.svg"
        class="logo"
        alt="LOGO"
      />
    </a>
    <div class="onboarding_section">
      <div class="row d-flex">
        <div class="col-md-5 p_none align-items-center xs_hide">
          <div class="container d-flex align-items-start">
            <img
              src="../../../assets/authentication/Rectangle-1.svg"
              class="mr-3 sm_hide mt-5"
              alt=""
            />
            <div class="">
              <h1 class="title">Reliable Feedback.</h1>
              <p class="text mt-4">
                Keep all product teams and more in sync. Let users know what
                coming, what has arrived and make it interesting for them to
                express their needs that your product can fill.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-7 d-flex custom_margin_top p_none">
          <div class="d-flex align-items-end sm_hide">
            <img src="../../../assets/authentication/Pfeil.svg" alt="" />
          </div>
          <div class="mx-auto m_none w-100">
            <div class="bg-white mt_sm_4 rounded ml-3">
              <div class="container p-3 p-lg-4">
                <div class="d-flex justify-content-center" *ngIf="type">
                  <ul class="nav nav-pills" id="pills-tab" role="tablist">
                    <li
                      class="nav-item"
                      role="presentation"
                      (click)="navigator('login?application=', 'fullscreen')"
                    >
                      <a class="nav-link" [ngClass]="{ active: path }">Login</a>
                    </li>
                    <li
                      class="nav-item"
                      role="presentation"
                      (click)="navigator('sign-up?application=', 'fullscreen')"
                    >
                      <a class="nav-link" [ngClass]="{ active: !path }"
                        >Sign Up</a
                      >
                    </li>
                  </ul>
                </div>
                <router-outlet></router-outlet>
                <div class="d-flex justify-content-center mt-3" *ngIf="!type">
                  <span class="forgot_text" *ngIf="signUp">
                    Already have an account?.
                    <button
                      class="btn btn-transparent forgot_text p-0"
                      (click)="navigator('login?application=')"
                    >
                      Log in
                    </button>
                  </span>
                  <span class="forgot_text" *ngIf="login"
                    >` Don't have an account?.
                    <button
                      class="btn btn-transparent forgot_text p-0"
                      (click)="navigator('sign-up?application=')"
                    >
                      Sign up
                    </button>
                  </span>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="container">
                      <div class="row margin_top fixed-bottom p-3 bg-dark">
                        <div
                          class="col-md-6 d-flex align-items-center justify-content-start"
                        >
                          <p class="footer_text m-0">
                            (c) 2020. Hoolio Limited
                          </p>
                        </div>
                        <div class="col-md-6 xs_hide">
                          <div
                            class="d-flex align-items-center justify-content-end"
                          >
                            <a routerLink="/">
                              <p class="footer_text m-0">Home</p>
                            </a>
                            <a routerLink="/terms-and-conditions">
                              <p class="footer_text mb-0 ml-3">
                                | <span class="ml-3"></span> Terms of Use
                              </p>
                            </a>
                            <a routerLink="/terms-and-conditions">
                              <p class="footer_text mb-0 ml-3">
                                | <span class="ml-3"></span> Privacy Policy
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
