<div class="wrapper">
  <div class="settings">
    <div class="bg h-100">
      <app-dashboard-nav></app-dashboard-nav>
      <div class="container">
        <div class="header mt-5">
          <a routerLink="/dashboard" class="dashboard">
            <img
              src="../../../assets/stories-details/arrow-right.svg"
              class="mr-2"
              alt=""
            />Dashboard
          </a>
          <h1 class="mt-5 title">Your account</h1>
        </div>
        <div class="container bg-white rounded p-0 mt-4">
          <div class="row">
            <div class="col-md-4 col-lg-4 p-3">
              <h1 class="header_title h1 ml-3">Monthly Plan</h1>
              <p
                class="link mb-0 ml-3"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Change plan
              </p>
            </div>
            <div class="col-md-4 col-lg-4 p-3">
              <h1 class="header_title h2 ml-3">Next Billing Date</h1>
              <p class="date_text mb-0 ml-3">14 August 2020</p>
            </div>
            <div class="col-md-5 col-lg-4 p-3">
              <h1 class="header_title h3 ml-3">Payment Method</h1>
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center ml-3">
                  <img src="../../../assets/settings/image 13.svg" alt="" />
                  <p class="card_number mb-0 ml-3">**** **** **** 4902</p>
                </div>
                <a href="" class="ml-2 link">Update</a>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 pb-5">
          <div class="row">
            <div class="col-lg-4 custom_top">
              <button
                class="bg-white nav-link border-0 w-100"
                routerLink="/my-profile"
              >
                <div class="d-flex justify-content-between align-items-center p-2">
                  <div class="d-flex align-items-center">
                    <img src="../../../assets/settings/Icon.svg" alt="" />
                    <p class="profile_text w-1 mb-0 mr-5 ml-2">My Profile</p>
                  </div>
                  <i class="fas fa-angle-right"></i>
                </div>
              </button>
            </div>
            <div class="col-lg-4">
              <button
                class="bg-white nav-link border-0 w-100 custom_top"
                routerLink="/billing-details"
              >
                <div class="d-flex justify-content-between align-items-center p-2">
                  <div class="d-flex align-items-center">
                    <img src="../../../assets/settings/lock.svg" alt="" />
                    <p class="profile_text w-2 mb-0 mr-5 ml-2">Billing Details</p>
                  </div>
                  <i class="fas fa-angle-right"></i>
                </div>
              </button>
            </div>
            <div class="col-lg-4">
              <button class="bg-white nav-link border-0 w-100 custom_top">
                <div class="d-flex justify-content-between align-items-center p-2">
                  <div class="d-flex align-items-center">
                    <img src="../../../assets/settings/lock.svg" alt="" />
                    <p class="profile_text w-3 mb-0 mr-5 ml-2">
                      Security & Privacy
                    </p>
                  </div>
                  <i class="fas fa-angle-right"></i>
                </div>
              </button>

            </div>
          </div>

          <!-- Modal -->
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable"
            >
              <div class="modal-content p-4">
                <div class="container">
                  <div class="d-flex justify-content-end">
                    <button
                      type="button"
                      class="custom_close mt-3"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"
                        ><svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.2127 16.75C14.8028 16.75 14.4441 16.5963 14.1366 16.2888L0.71118 2.86335C0.0962733 2.24845 0.0962733 1.27484 0.71118 0.71118C1.32609 0.0962733 2.29969 0.0962733 2.86335 0.71118L16.2888 14.0854C16.9037 14.7003 16.9037 15.6739 16.2888 16.2376C15.9814 16.5963 15.5714 16.75 15.2127 16.75Z"
                            fill="#003456"
                          />
                          <path
                            d="M1.78727 16.75C1.37733 16.75 1.01863 16.5963 0.71118 16.2888C0.0962733 15.6739 0.0962733 14.7003 0.71118 14.1366L14.0854 0.71118C14.7003 0.0962733 15.6739 0.0962733 16.2376 0.71118C16.8525 1.32609 16.8525 2.29969 16.2376 2.86335L2.86335 16.2888C2.5559 16.5963 2.19721 16.75 1.78727 16.75Z"
                            fill="#003456"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                  <h5 class="modal-title" id="exampleModalLabel">
                    Change Plan
                  </h5>
                </div>
                <div class="modal-body mt-4">
                  <button class="dark_btn w-100">
                    <img
                      src="../../../assets/settings/tick.svg"
                      class="mr-3 ml-3"
                      alt=""
                    />
                    $25/month
                  </button>
                  <button class="light_btn w-100 mt-4">
                    <img
                      src="../../../assets/billing_details/Vector (1).svg"
                      class="ml-3 mr-3"
                      alt=""
                    />
                    $150/year
                  </button>

                  <div class="text-center mt-5">
                    <h3 class="sub_header">Select this plan?</h3>
                    <p class="text">
                      This will change your current plan, click change plan to
                      proceed
                    </p>
                    <button
                      class="dark_btn w-100 mt-4 d-flex justify-content-center"
                    >
                      Change plan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
