<div class="wrapper">
  <div class="row">
    <div class="col-12 footer_bg">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <img src="../../../assets/footer/Group 263.svg" alt="" />
          </div>
          <div class="col-md-2 sm_padding">
            <h3 class="footer_title">Support</h3>
            <ul>
              <li>
                <a href="#" class="mt-4 footer_link">feedback</a>
              </li>
              <li>
                <a href="#" class="footer_link">performance</a>
              </li>
              <li>
                <a href="#" class="footer_link">talk</a>
              </li>
              <li>
                <a href="#" class="footer_link">tickets</a>
              </li>
              <li>
                <a href="#" class="footer_link">knowledge</a>
              </li>
            </ul>
          </div>
          <div class="col-md-2">
            <h3 class="footer_title">Growth</h3>
            <ul>
              <li>
                <a href="#" class="footer_link">reports</a>
              </li>
              <li>
                <a href="#" class="footer_link">accounts</a>
              </li>
              <li>
                <a href="#" class="footer_link">schedule</a>
              </li>
              <li>
                <a href="#" class="footer_link">book</a>
              </li>
              <li>
                <a href="#" class="footer_link">email</a>
              </li>
            </ul>
          </div>
          <div class="col-md-2">
            <h3 class="footer_title">Manage</h3>
            <ul>
              <li>
                <a href="#" class="mt-4 footer_link">users</a>
              </li>
              <li>
                <a href="#" class="footer_link">talent</a>
              </li>
              <li>
                <a href="#" class="footer_link">contact</a>
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <h3 class="footer_title">Resources</h3>
            <ul>
              <li>
                <a routerLink="/contact" class="mt-4 footer_link">Contact us</a>
              </li>
              <li>
                <a routerLink="/faq" class="footer_link">FAQ</a>
              </li>
              <li>
                <a routerLink="/pricing" class="footer_link">Pricing</a>
              </li>
              <li>
                <a routerLink="/customer-stories" class="footer_link"
                  >Customer Stories</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="display-none">
          <div
            class="d-flex justify-content-between align-items-center border_top mt-5"
          >
            <p class="t_and_c m-0 pt-3">© 2020 Hoolio, All Rights Reserved</p>
            <div class="d-flex align-items-center justify-content-between pt-3">
              <a routerLink="/terms-and-conditions" class="t_and_c"
                >Terms and Conditions</a
              >
              <a routerLink="/privacy" class="t_and_c ml-4">Privacy</a>
              <div
                class="d-flex align-items-center justify-content-between ml-5"
              >
                <i class="fab fa-twitter footer_icon"></i>

                <i class="ml-3 fab fa-facebook-f footer_icon"></i>
                <i class="fab fa-google ml-3 footer_icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
