<div class="homepage">
  <div class="header">
    <app-navbar></app-navbar>
    <app-header></app-header>
  </div>
  <app-tab></app-tab>
  <app-support></app-support>
  <app-grid></app-grid>
  <app-revenue></app-revenue>
  <app-experience></app-experience>
  <app-footer></app-footer>
</div>
