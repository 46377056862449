<div class="wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-6 d-flex align-items-center">
        <div class="">
          <h2 class="title mb-5">Contact us</h2>
          <img src="../../../../assets/contact/Ellipse 9.svg" alt="">
          <h4 class="sub_header mt-4">Product and Account Support</h4>
          <p class="text mt-4">Our help center is fresh and always open for business. If you can’t find the answer you’re looking for, we’re here to
          lend a hand.</p>
          <button class="btn custom_btn_dark mt-4 mb-4">Go to FAQ</button>
        </div>
      </div>
      <div class="col-md-6">
        <img src="../../../../assets/contact/Rectangle 136.svg" class="header-img" alt="">
      </div>
    </div>
  </div>
</div>
