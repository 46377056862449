<div class="faq">
  <app-faq-header></app-faq-header>
  <div class="header">
    <div class="container">
      <div class="row mt-5 pt-5">
        <div class="col-md-3">
          <div
            class="nav flex-column nav-pills side_nav"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <a
              class="nav-link active nav_link"
              id="v-pills-general-tab"
              data-toggle="pill"
              href="#v-pills-general"
              role="tab"
              aria-controls="v-pills-general"
              aria-selected="true"
              >General</a
            >
            <a
              class="nav-link"
              id="v-pills-accounts-tab"
              data-toggle="pill"
              href="#v-pills-accounts"
              role="tab"
              aria-controls="v-pills-accounts"
              aria-selected="false"
              >Accounts</a
            >
            <a
              class="nav-link"
              id="v-pills-sales-tab"
              data-toggle="pill"
              href="#v-pills-sales"
              role="tab"
              aria-controls="v-pills-sales"
              aria-selected="false"
              >Sales</a
            >
            <a
              class="nav-link"
              id="v-pills-support-tab"
              data-toggle="pill"
              href="#v-pills-support"
              role="tab"
              aria-controls="v-pills-support"
              aria-selected="false"
              >Support</a
            >
            <a
              class="nav-link"
              id="v-pills-license-tab"
              data-toggle="pill"
              href="#v-pills-license"
              role="tab"
              aria-controls="v-pills-license"
              aria-selected="false"
              >License</a
            >
            <a
              class="nav-link"
              id="v-pills-refund-tab"
              data-toggle="pill"
              href="#v-pills-refund"
              role="tab"
              aria-controls="v-pills-refund"
              aria-selected="false"
              >Refund</a
            >
          </div>
        </div>
        <div class="col-md-9 custom_margin_top">
          <div class="tab-content" id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="v-pills-general"
              role="tabpanel"
              aria-labelledby="v-pills-general-tab"
            >
              <h2 class="title">Can I use Omega for my clients?</h2>
              <p class="text mt-4">
                Create custom landing pages with Omega that converts more
                visitors than any website. With lots of unique blocks, you can
                easily build a page without any design or custom coding. with
                Omega that converts more visitors than any website.
              </p>
              <h2 class="title mt-4">Do I get free updates?</h2>
              <p class="text mt-4">
                Create custom landing pages with Omega that converts more
                visitors than any website. With lots of unique blocks, you can
                easily build a page without any design or custom coding.
              </p>
              <h2 class="title mt-4">Does it work with WordPress?</h2>
              <p class="text mt-4">
                Create custom landing pages with Omega that converts more
                visitors than any website. With lots of unique blocks, you can
                easily build a page without any design or custom coding.
              </p>
              <h2 class="title mt-4">Will you provide support?</h2>
              <p class="text mt-4">
                Create custom landing pages with Omega that converts more
                visitors than any website. With lots of unique blocks, you can
                easily build a page without any design or custom coding.
              </p>
              <p class="text mt-5">
                Didn’t find your answer?
                <a routerLink="/contact" class="span">Contact us here </a>
              </p>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-accounts"
              role="tabpanel"
              aria-labelledby="v-pills-accounts-tab"
            >
              <p class="text">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga
                recusandae quos, quia perferendis mollitia rerum nemo assumenda
                maxime debitis, beatae aliquid nisi inventore vel facere
                incidunt! Eos minus tempora quos.
              </p>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-sales"
              role="tabpanel"
              aria-labelledby="v-pills-sales-tab"
            >
              <p class="text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Assumenda eligendi, aliquid nobis laudantium veniam incidunt
                repudiandae qui corrupti hic, esse vel illo asperiores? Facilis
                similique numquam corporis laudantium id eaque.
              </p>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-support"
              role="tabpanel"
              aria-labelledby="v-pills-support-tab"
            >
              <p class="text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Repellat aliquam delectus, ex aliquid rerum perspiciatis
                expedita! Dolorum perspiciatis, voluptatem autem magnam possimus
                dolorem nobis fuga eos. Fugit adipisci quod hic.
              </p>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-license"
              role="tabpanel"
              aria-labelledby="v-pills-license-tab"
            >
              <p class="text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Repellat aliquam delectus, ex aliquid rerum perspiciatis
                expedita! Dolorum perspiciatis, voluptatem autem magnam possimus
                dolorem nobis fuga eos. Fugit adipisci quod hic.
              </p>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-refund"
              role="tabpanel"
              aria-labelledby="v-pills-refund-tab"
            >
              <p class="text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Repellat aliquam delectus, ex aliquid rerum perspiciatis
                expedita! Dolorum perspiciatis, voluptatem autem magnam possimus
                dolorem nobis fuga eos. Fugit adipisci quod hic.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
