<div class="customer-stories-details wrapper">
  <div class="header">
    <app-terms-header></app-terms-header>
    <app-c-details-header></app-c-details-header>
  </div>
  <app-application></app-application>
  <app-how-it-works></app-how-it-works>
  <app-experience></app-experience>
  <app-footer></app-footer>
</div>
