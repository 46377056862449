<div>
  <h1 class="nav-link active text-center" *ngIf="!type">Login</h1>
  <form [formGroup]="loginForm">
    <div
      class="input_field d-flex align-items-center mt-4 justify-content-between pl-3 pr-3"
    >
      <input
        type="email"
        class="input_area"
        placeholder="Email address"
        formControlName="email"
        [ngClass]="{
          'is-invalid':
            loginForm.get('email').touched && loginForm.get('email').errors
        }"
      />
      <button class="btn btn-transparent p-0 mr-2">
        <i class="fa fa-envelope" aria-hidden="true"></i>
      </button>
    </div>
    <p
      class="text-danger forgot_text ml-3 pl-1"
      *ngIf="hasError('email', 'email')"
    >
      Enter a valid email address
    </p>
    <p
      class="text-danger forgot_text ml-3 pl-1"
      *ngIf="hasError('email', 'required')"
    >
      Email address is required
    </p>

    <div
      class="input_field d-flex align-items-center mt-3 mb-2 justify-content-between pl-3 pr-3"
    >
      <input
        [type]="show ? 'text' : 'password'"
        class="input_area"
        placeholder="password"
        formControlName="password"
        name=""
        id=""
      />
      <button
        class="btn bg-transparent p-0 mr-2"
        (click)="password()"
        *ngIf="show"
      >
        <i class="fas fa-eye"></i>
      </button>
      <button
        class="btn bg-transparent p-0 mr-2"
        (click)="password()"
        *ngIf="!show"
      >
        <i class="fas fa-eye-slash"></i>
      </button>
    </div>
    <a href="/onboarding/forgot-password" class="forgot_text mt-3 pl-3">
      Forgot Password?
    </a>
    <button
      [ngClass]="{ disabled_btn: loginForm.invalid }"
      [disabled]="loginForm.invalid"
      class="btn w-100 btn_login mt-4"
      (click)="loginUser()"
    >
      Login
    </button>
  </form>
</div>
