import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HoolioAuthService {
  constructor(private _http: HttpClient) {}

  // Login
  login(payload: any): Observable<any> {
    return this._http.post(`${environment.url}/login`, payload);
  }

  // Register
  signUp(payload: any): Observable<any> {
    return this._http.post(`${environment.url}/register`, payload);
  }

  // Forgot Password
  forgotPassword(payload: any): Observable<any> {
    return this._http.post(`${environment.url}/start-forget-password`, payload);
  }

  // Reset Password
  resetPassword(payload: any): Observable<any> {
    return this._http.post(
      `${environment.url}/finish-forgot-password`,
      payload
    );
  }

  // Verify Email
  verifyEmail(payload: any): Observable<any> {
    return this._http.post(`${environment.url}/verify-email`, payload);
  }

  // Resend Code
  resendCode(payload: any): Observable<any> {
    return this._http.post(`${environment.url}/resend-email`, payload);
  }
}
