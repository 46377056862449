<div class="custom_margin">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 d-flex align-items-center">
        <div class="">
          <h1 class="title">
            Experience the best application suite with Hoolio
          </h1>
          <div class="mt-5">
            <button routerLink="/pricing" class="btn custom_btn_dark">Pricing</button>
            <button class="btn custom_btn_light ml-3">Watch the video</button>
          </div>
          <div class="mt-5">
            <a routerLink="/customer-stories" class="link_text">View Customer Stories</a>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="position-relatice">
          <div
            class="d-flex justify-content-center align-items-center mt-5 pt-5"
          >
            <img
              src="../../../../assets/homepage/Rectangle 131.png"
              class="sm-w-100"
              alt=""
            />
          </div>
          <div class="card-img-overlay sm_hide">
            <div class="d-flex justify-content-end">
              <img
                src="../../../../assets/homepage/Rectangle 132.png"
                class="mr-3"
                alt=""
              />
            </div>
            <div class="d-flex justify-content-end mt-5">
              <img
                src="../../../../assets/homepage/Rectangle 133.png"
                class="mt-5 pt-5"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
