<div class="wrapper">

  <div class="container">
    <div class="row mt-5">
      <div class="col-md-5 d-flex align-items-center">
        <div class="">
          <h2 class="title">Our customers share their stories</h2>
          <p class="text mt-4">
            See how businesses like yours found solutions with The Hoolio
            Applications Suite.
          </p>
          <div class="mt-5">
            <button routerLink="/login" class="btn btn_dark">Get Started</button>
            <button class="btn ml-3 btn_light">Watch the video</button>
          </div>
        </div>
      </div>
      <div class="col-md-7 d-flex align-items-center justify-content-end">
        <div class="">
          <img
            src="../../../../assets/customer-stories/Rectangle 130.svg"
            class="main_img"
            alt=""
          />
          <div
            class="card-img-overlay d-flex flex-column justify-content-start m-0"
          >
            <img
              src="../../../../assets/customer-stories/Rectangle 132.svg"
              class="custom-imgs"
              alt=""
            />
            <img
              src="../../../../assets/customer-stories/Rectangle 133.svg"
              class="mt-5 custom-imgs"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
