import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as Notiflix from 'notiflix';
import { HoolioAuthService } from 'src/app/shared/services/auth/hoolio-auth.service';

@Component({
  selector: 'app-recovery-component',
  templateUrl: './recovery-component.component.html',
  styleUrls: ['./recovery-component.component.css'],
})
export class RecoveryComponentComponent implements OnInit {
  emailForm: FormGroup | any;

  constructor(
    private _fb: FormBuilder,
    private _auth: HoolioAuthService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.emailForm = this._fb.group({
      email: ['', [Validators.email, Validators.required]],
    });
  }

  submit() {
    if (!this.emailForm.valid) {
      Notiflix.Notify.failure('Enter a valid email address', {
        useIcon: false,
        timeout: 5000,
      });
    } else {
      Notiflix.Loading.circle();
      this._auth.forgotPassword(this.emailForm.value).subscribe(
        (response: any) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.success(response.message, {
            useIcon: false,
            timeout: 5000,
          });
          localStorage.setItem('reset_email', this.emailForm.value.email);
          this._router.navigate(['/onboarding/change-password']);
        },
        (error) => {
          Notiflix.Loading.remove();
        }
      );
    }
  }
}
