<div class="login-signin">
  <div class="bg">
    <div class="container pt-4">
      <div class="navbar-brand">
        <a routerLink="/">
          <img src="../../assets/authentication/login-logo.svg" alt="" />
        </a>
      </div>
      <app-login></app-login>
      <div class="row">
        <div class="col-12">
          <div class="container">
            <div class="row margin_top fixed-bottom pt-3 bg-dark">
              <div
                class="col-md-6 d-flex align-items-center justify-content-start"
              >
                <p class="footer_text m-0">(c) 2020. Hoolio Limited</p>
              </div>
              <div class="col-md-6">
                <div class="d-flex align-items-center justify-content-end">
                  <a routerLink="/">
                    <p class="footer_text m-0">Home</p>
                  </a>
                  <a routerLink="/terms-and-conditions">
                    <p class="footer_text mb-0 ml-3">
                      | <span class="ml-3"></span> Terms of Use
                    </p>
                  </a>
                  <a routerLink="/terms-and-conditions">
                    <p class="footer_text mb-0 ml-3">
                      | <span class="ml-3"></span> Privacy Policy
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <img
    src="../../assets/authentication/Pfad-783.svg"
    class="m-0 p-0 bg_image"
    alt=""
  />
</div>
