import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c-details-header',
  templateUrl: './c-details-header.component.html',
  styleUrls: ['./c-details-header.component.css']
})
export class CDetailsHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
