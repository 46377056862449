<div class="container mt-5 pt-5 pb-5">
  <div class="row">
    <div class="col-md-6 d-flex align-items-center">
      <div class="">
        <h1 class="title">Get the right plan for future</h1>
      </div>
    </div>
    <div class="col-md-6 d-flex align-items-center justify-content-center jce">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active c_btn rounded-left d-flex align-items-center justify-content-center"
            id="pills-home-tab"
            data-toggle="pill"
            href="#pills-home"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            >Yearly</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link c_btn rounded-right d-flex align-items-center justify-content-center"
            id="pills-profile-tab"
            data-toggle="pill"
            href="#pills-profile"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
            >Monthly</a
          >
        </li>
      </ul>
    </div>
  </div>
  <div class="row mt-5 pt-5 text-center">
    <div class="col-lg-4 d-flex align-items-center">
      <div class="mx-auto starter_bg w-75 h-100 p-4">
        <h6 class="sub_header">Starter</h6>
        <h1 class="title_header mt-4 pb-4">Free</h1>
        <ul>
          <li class="text">1 Website</li>
          <li class="text">5 GB Hosting</li>
          <li class="text">Limited Support</li>
        </ul>
        <button class="btn custom_btn_primary w-100 mt-4 light_btn">
          Get Started
        </button>
      </div>
    </div>
    <div class="col-lg-4 d-flex align-items-center smm">
      <div class="premium_bg w-75 h-100 mx-auto p-4">
        <h6 class="sub_header premium_text">Starter</h6>
        <h1 class="text-white title_header mt-4 pb-4">
          $29<span>/month</span>
        </h1>
        <ul>
          <li class="premium_text">10 Website</li>
          <li class="premium_text">15 GB Hosting</li>
          <li class="premium_text">Premium Support</li>
        </ul>
        <button class="btn primary_btn w-100 mt-4">Get Started</button>
      </div>
    </div>
    <div class="col-lg-4 d-flex align-items-center smm">
      <div class="starter_bg w-75 h-100 mx-auto p-4">
        <h6 class="sub_header">Starter</h6>
        <h1 class="title_header mt-4 pb-4">$49<span>/month</span></h1>
        <ul>
          <li class="text">Unlimited Website</li>
          <li class="text">50 GB Hosting</li>
          <li class="text">Premium Support</li>
        </ul>
        <button class="btn w-100 mt-4 light_btn">Get Started</button>
      </div>
    </div>
  </div>
</div>
