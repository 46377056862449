import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  toggle: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  sidebarToggler = () => {
    this.toggle = !this.toggle;
  };

  setContainerClasses() {
    let myClasses = {
      zero: this.toggle === false,
      two_hundred: this.toggle === true,
    };
    return myClasses;
  }

  setPaneClasses() {
    let myPaneClasses = {
      zero: this.toggle === true,
      four_thirty: this.toggle === false,
    };
    return myPaneClasses;
  }

  setWidthClasses() {
    let myWidthClasses = {
      slide_width: this.toggle === true,
      full_width: this.toggle === false,
    };
    return myWidthClasses;
  }
}
